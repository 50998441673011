import molecule from '@dtinsight/molecule'
import { useGlobalState } from '../state/state'

/**
 * Use Molecule output panel hook
 */
export const useOutputPanel = () => {
  const [outputHeight, _setHeight] = useGlobalState('outputPanelHeight')

  /**
   * Scrolls down to the latest output
   */
  const scrollDown = () => {
    const scrollHeight = molecule.panel.outputEditorInstance.getScrollHeight()
    const lineHeight = 16
    const lineNumber = Number(
      ((scrollHeight - outputHeight) / lineHeight).toFixed()
    )

    molecule.panel.outputEditorInstance.revealLine(lineNumber)
  }

  return {
    scrollDown,
  }
}
