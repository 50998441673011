import molecule from '@dtinsight/molecule'
import {
  Float,
  IEditorTab,
  IFolderTreeNodeProps,
  INotificationItem,
  IStatusBarItem,
} from '@dtinsight/molecule/esm/model'
import { Icon } from '@dtinsight/molecule/esm/components'

import dayjs from 'dayjs'
import { DEVICE_STATE } from '../api/serialProvider'

const STATUS_BAR_LANGUAGE: IStatusBarItem = {
  id: 'LanguageStatus',
  sortIndex: 3,
}

enum NotificationLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const transformToEditorTab = (item: IFolderTreeNodeProps): IEditorTab => {
  const tabData: IEditorTab = item
  tabData.breadcrumb = item.location
    ? item.location
        .split('/')
        .map((local: string) => ({ id: local, name: local }))
    : []
  return tabData
}

const updateStatusBarLanguage = (language: string) => {
  if (!language) {
    return
  }

  language = language.toUpperCase()

  const languageStatusItem = molecule.statusBar.getStatusBarItem(
    STATUS_BAR_LANGUAGE.id,
    Float.right
  )
  if (!languageStatusItem) {
    molecule.statusBar.add(
      Object.assign({}, STATUS_BAR_LANGUAGE, { name: language }),
      Float.right
    )
  } else if (languageStatusItem.name !== language) {
    languageStatusItem.name = language
    molecule.statusBar.update(languageStatusItem, Float.right)
  }
}

// const updateStatusBar = (state: DEVICE_STATE, message?: string, onClick?) => {
//   console.log(`[StatusBar] Update device state to: ${state}`)

//   const DEVICE_STATUS_BAR: IStatusBarItem = {
//     id: 'deviceStatusBar',
//     name: 'DeviceStatus',
//     render: () => <b></b>,
//     onClick: () => {},
//   }

//   console.log('[StatusBar] Update background color...')

//   // status bar background color
//   const bgColor = getStatusBarColor(state)
//   document.documentElement.style.setProperty('--statusBar-background', bgColor)
//   document.documentElement.style.setProperty(
//     '--statusBar-foreground',
//     getContrastColor(bgColor)
//   )

//   console.log('[StatusBar] Setting text...')
//   // status bar message
//   const text = message ?? deviceStateMessages(state) ?? ''
//   let payload = {
//     ...DEVICE_STATUS_BAR,
//     render: () => <p>{text}</p>,
//   }

//   if (onClick) {
//     payload.onClick = onClick
//   }

//   const deviceStatusBar = molecule.statusBar.getStatusBarItem(
//     DEVICE_STATUS_BAR.id
//   )
//   if (!deviceStatusBar) {
//     molecule.statusBar.add(payload, Float.left)
//   } else {
//     molecule.statusBar.update(payload, Float.left)
//   }
// }

let notificationTimeouts = {}

/**
 * Show notification and log its message to output panel
 * @param id
 * @param message
 * @param level
 * @param autohide
 */
const showNotification = (
  id: string,
  message: string,
  icon: string = undefined,
  level: NotificationLevel = NotificationLevel.INFO,
  appendOutput: boolean = false,
  autohide: boolean = true
): void => {
  // log message into a output panel
  if (appendOutput) {
    molecule.panel.appendOutput(
      `${dayjs().format('HH:mm:ss.SSS')} [${id}] ${message}\n`
    )
  }

  // get current notification state
  const state = molecule.notification.getState()

  // prepare notification payload
  const payload = {
    id: id,
    status: 1,
    value: message,
    render: (item) => (
      <p>
        <Icon
          type={icon ?? level}
          style={{
            color: level === NotificationLevel.ERROR ? '#75000a' : 'inherit',
          }}
        />
        {item.value}
      </p>
    ),
  }

  // check if notification with same id is already shown
  let notification: INotificationItem = state.data.find((x) => x.id === id)
  if (!notification) {
    molecule.notification.add([payload])
  } else {
    molecule.notification.update(payload)
  }

  if (!state.showNotifications) {
    molecule.notification.toggleNotification()
  }

  if (autohide) {
    if (notificationTimeouts[id]) {
      clearTimeout(notificationTimeouts[id])
    }

    notificationTimeouts[id] = setTimeout(() => {
      molecule.notification.remove(id)

      if (molecule.notification.getState().data.length === 0) {
        molecule.notification.toggleNotification()
      }
    }, 10000)
  }
}

const showOutputPanel = () => {
  const id = molecule.builtin.getConstant('PANEL_OUTPUT')?.value
  if (!id) {
    console.error(`Could not find PANEL_OUTPUT value!`)
    return
  }

  try {
    molecule.panel.setActive(id)
  } catch (ex) {
    console.warn(`Could not set PANEL_OUTPUT as active! ${ex}`)
  }
}

const showProblemsPanel = () => {
  const id = molecule.builtin.getConstant('PANEL_PROBLEMS')?.value
  if (!id) {
    console.error(`Could not find PANEL_PROBLEMS value!`)
    return
  }

  try {
    molecule.panel.setActive(id)
  } catch (ex) {
    console.warn(`Could not set PANEL_PROBLEMS as active! ${ex}`)
  }
}

export {
  transformToEditorTab,
  updateStatusBarLanguage,
  NotificationLevel,
  showNotification,
  showOutputPanel,
  showProblemsPanel,
}
