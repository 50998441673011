import { NotificationLevel, showNotification } from '../common/'

import { EVENT_TYPE, DEVICE_STATE } from './serialProvider'

import {
  serialBootloaderSendBootCommand,
  serialBootloaderFSM,
} from './bootloader'

import { sendFrame } from './serialApi'

import FrameTypes, { REPLY_TYPE } from './frameTypes'

import { ACR_BAUDRATES } from './serialDriver'

import {
  acrComReceiverGetState,
  acrComReceiverGetReceivedFrame,
  acrComReceiverStart,
  acrComReceiverFSM,
  ACRCOM_RECEIVER_STATE,
  acrComSendDynamicNGFrame,
  ACRCOM_COMMAND_ID,
} from './acrCom'

import { parseFwVersion } from './fwVersion'

import LuaCompiler from './luac/luac.js'

import { decompress } from './lzf'
import { useGlobalState } from '../state/state'

enum DETECTOR_STATE {
  IDLE = 'idle',
  SEND_NOOP = 'send-noop',
  LOOK_FOR_ACK_DOTS_BOOT = 'look-for-ack-dots-boot',
  WAIT_FOR_NG_BL = 'wait-for-ng-bl',

  REQUEST_DEV_TYPE = 'request-dev-type',
  REQUEST_DEV_VERSION = 'request-dev-version',
  REQUEST_LUA_INFO = 'request-lua-info',
  REQUEST_LUA_INFO_WAIT_FOR_ANSWER = 'request-lua-info-wait',
  REQUEST_LAST_ERR = 'request-last-err',
  REQUEST_LAST_ERR_TRACEBACK = 'request-last-err-traceback',
  REQUEST_LAST_ERR_STDOUT = 'request-last-err-stdout',
  REQUEST_LORAWAN_KEYS = 'request-lorawan-keys',

  DONE = 'done',
}

const detectorCtx = {
  legacy: false,
  disableReadingLuaInfo: true, // set to false later
  disableReadingErrorDetails: true, // set to false later

  state: DETECTOR_STATE.IDLE,
  rxBuffer: [],
  sentNoopTimestamp: 0,
  startNgFSMTimestamp: 0,

  ackPattern: new TextEncoder().encode(
    `${REPLY_TYPE.ACK}${FrameTypes.NOOP.id}`
  ),
  ackPatternPosition: 0,

  dotsPattern: Array(30).fill('.').join(''),
  dotsPatternPosition: 0,
  dotsPatternDetectedCount: 0,

  bootPattern: '[BOOT]',
  bootPatternPosition: 0,

  ngBeaconReceivedCount: 0,
  tryDetectNg: true,
  tryDetectLegacy: true,

  startDetectorTimestamp: 0,
  warningNotShown: true,

  requestTimestamp: 0,
  requestTrial: 0,
  selectedAnswer: '',
  selectedAnswerPosition: 0,
  literalAnswers: [],

  detectedDevType: '',
  detectedFirmwareVersion: '',
  luaGlobals: {},
  detectedLastError: {},
  detectedLoRaWANKeys: {},
  requestTimeout: 0,

  lastRxTimestamp: 0,
}

const DOTS_DETECTED_COUNT_TRESHOLD = 20
const NG_BEACON_COUNT_TRESHOLD = 3
const ACK_DOTS_BOOT_TIMEOUT = 100
const NG_BL_TRY_BEACON_TIMEOUT_MIN = 1000
const NG_BL_TRY_BEACON_TIMEOUT_MAX = 3000
let NG_BL_TRY_BEACON_TIMEOUT = NG_BL_TRY_BEACON_TIMEOUT_MIN
const DETECTOR_SHOW_NOTIFICATION_TIMEOUT = 10000
const TOTAL_DETECTOR_TIMEOUT = 120000
const REQUEST_TIMEOUT_NO_RX = 200
const REQUEST_TIMEOUT = 2000
const REQUEST_MAX_TRIALS = 3

const startDetector = () => {
  detectorCtx.requestTimeout = REQUEST_TIMEOUT
  detectorCtx.ngBeaconReceivedCount = 0
  detectorCtx.state = DETECTOR_STATE.SEND_NOOP
  detectorCtx.rxBuffer = []
  detectorCtx.ackPatternPosition = 0
  detectorCtx.dotsPatternPosition = 0
  detectorCtx.bootPatternPosition = 0
  detectorCtx.dotsPatternDetectedCount = 0
  detectorCtx.requestTimestamp = 0
  NG_BL_TRY_BEACON_TIMEOUT = NG_BL_TRY_BEACON_TIMEOUT_MIN
  detectorCtx.tryDetectNg = false //true;
  detectorCtx.tryDetectLegacy = true
  detectorCtx.warningNotShown = true
  detectorCtx.startDetectorTimestamp = new Date().valueOf()
  detectorCtx.selectedAnswer = ''
  detectorCtx.selectedAnswerPosition = 0

  console.log(`[STATE DETECTOR] Starting detector...`)
}

const AckReceptionHelper = (requireZeroTermination = false) => {
  let receptionDone = false
  let ansPos = -1
  let ans = []
  for (const b of detectorCtx.rxBuffer) {
    ansPos += 1
    // answer to look for is selected, try to match character by character
    if (detectorCtx.selectedAnswer !== '') {
      // if non matching character is encountered, the process of searching the
      // selected answer to match with is restarted by clearing the selectedAnswer variable
      if (
        b !==
        detectorCtx.selectedAnswer.charCodeAt(
          detectorCtx.selectedAnswerPosition
        )
      ) {
        detectorCtx.selectedAnswer = ''
      } else {
        detectorCtx.selectedAnswerPosition += 1
        if (
          detectorCtx.selectedAnswerPosition ===
          detectorCtx.selectedAnswer.length
        ) {
          receptionDone = true
          ans = detectorCtx.rxBuffer.slice(ansPos)
          break
        }
      }
    }

    if (detectorCtx.selectedAnswer === '') {
      // iterate over possible ASCII answers and try to match with the first character
      for (const litVal of detectorCtx.literalAnswers) {
        if (b === litVal.charCodeAt(0)) {
          detectorCtx.selectedAnswer = litVal
          detectorCtx.selectedAnswerPosition = 1

          // address the corner case, be a good programmer: case if the selected answer is single character
          if (detectorCtx.selectedAnswer.length === 1) {
            ans = detectorCtx.rxBuffer.slice(ansPos)
            receptionDone = true
          }
          break
        }
      }
    }
  }

  // we have the whole matching ASCII expressing, decide what to do based on the value
  if (receptionDone) {
    if (detectorCtx.selectedAnswer.startsWith('ACK')) {
      if (requireZeroTermination) {
        if (ans[ans.length - 1] !== 0) {
          return { done: false }
        } else {
          return { done: true, result: 'ok', data: ans }
        }
      } else {
        return { done: true, result: 'ok', data: ans }
      }
    } else if (detectorCtx.selectedAnswer.startsWith('ERR')) {
      return { done: true, result: 'error' }
    }
  }
  return { done: false }
}

const bufferToStringHelper = (buf, start = 0) => {
  if (buf[buf.length - 1] === 0) {
    return new TextDecoder('ascii').decode(
      new Uint8Array(buf.slice(start, buf.length - 1))
    )
  } else {
    return new TextDecoder('ascii').decode(new Uint8Array(buf.slice(start)))
  }
}

const rpcExecuteIsolatedCompiledLua = async (port, luaRequest) => {
  console.log(luaRequest)

  const runLua = new Promise((resolve, reject) => {
    LuaCompiler({
      debugSymbols: false,
      input: luaRequest,
      //Callback that processes sucessfull output
      callback: (payload) => {
        //Concat buffer to create payload
        resolve(payload)
      },
      //Callback for stdout (Usually for handling the compilation issue)
      stdout: (output) => {
        reject(output)
      },
    })
  })

  const payload = await Promise.resolve(runLua)
  let data = payload as Uint8Array

  detectorCtx.requestTimeout = REQUEST_TIMEOUT
  await acrComSendDynamicNGFrame(
    port,
    ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED,
    data
  )
  acrComReceiverStart()
}

const requestLastErrorTypeSendHelper = async (port, type, si, ei) => {
  const luaRequest = `
pp = pack.pack
ex = api.exec

function acrComSend(t, id, d)
    a = pp("b3", 1, t, id) .. d
    ex("acrComTx", a)
end

function tohex(arr)
    ret = ""
    for i = 1, #arr do
      ret = ret .. string.format("%02X", arr:byte(i))
    end
    return ret
end

acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, "ACK")

c = "${type}"
eLen = ex("_get_last_error", c, "len")
si = ${si}
ei = ${ei}
if ei >= eLen then
    ei = eLen - 1
end
e = ex("_get_last_error", c, si, ei)
send = '{"startIndex": ' .. tostring(si) ..', "stopIndex": '.. tostring(ei) ..', "totalLength": '.. tostring(eLen) ..'}'..e
acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, send)
`
  await rpcExecuteIsolatedCompiledLua(port, luaRequest)
}

const requestGlobalsSendHelper = async (port) => {
  const luaRequest = `
local function dumpTable(tbl)
    local ret = ""
    local isFirst = true
    for kk,vv in pairs(tbl) do
        if isFirst == true then
            isFirst = false
        else
            ret = ret .. ','
        end

        if type(tbl[kk]) == "table" and kk ~= "_G" and kk ~= "loaded" then
            ret = ret .. '"'.. kk ..'": {'
            ret = ret .. dumpTable(tbl[kk])
        else
        ret = ret .. '"'.. kk ..'": "'.. type(tbl[kk]) .. '"'
        end
    end
    ret = ret .. '}'
    return ret
end

local function doDump()
    return '{' .. dumpTable(_G)
end
local function acrComSend(t, id, d)
    local a = pack.pack("b3", 1, t, id) .. d
    api.exec("acrComTx", a)
end
acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, "ACK")
acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, doDump())
    `
  await rpcExecuteIsolatedCompiledLua(port, luaRequest)
}

const requestLastErrorShortSendHelper = async (port) => {
  const luaRequest = `
pp = pack.pack
ex = api.exec
function acrComSend(t, id, d)
    a = pp("b3", 1, t, id) .. d
    ex("acrComTx", a)
end
acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, "ACK")
short = ex("_get_last_error", "SHORT")
send = '{"short": "'.. short ..'"}'
acrComSend(1, ${ACRCOM_COMMAND_ID.ACRCOM_CMD_LUA_EXECUTE_ISOLATED}, send)
`

  await rpcExecuteIsolatedCompiledLua(port, luaRequest)
}

const processRequestLastErrorState = async (
  port,
  now,
  event,
  data,
  typeName,
  nextState,
  propertyName,
  nextPropertyName?,
  nextTypeName?
) => {
  await acrComReceiverFSM(port, event, data)
  let retry = false
  if (acrComReceiverGetState() === ACRCOM_RECEIVER_STATE.RECEIVED) {
    const rawData = acrComReceiverGetReceivedFrame()
    console.log(rawData) // TODO, unmarshall to detectorCtx.detectedLastError object
    const jsonEnd = rawData.indexOf('}'.charCodeAt(0)) + 1
    let textData
    if (jsonEnd !== 0) {
      textData = new TextDecoder().decode(
        new Uint8Array(rawData.slice(3, jsonEnd))
      )
    } else {
      textData = new TextDecoder().decode(new Uint8Array(rawData.slice(3)))
    }
    if (textData === 'ACK') {
      detectorCtx.requestTimeout = 5 * REQUEST_TIMEOUT
      detectorCtx.requestTimestamp = now
      acrComReceiverStart()
    } else {
      console.log(textData)
      let jsonData = JSON.parse(textData)
      const binData = rawData.slice(jsonEnd)
      jsonData['hex'] = binData.reduce((a, b) => {
        const asHex = b.toString(16)
        if (asHex.length === 1) {
          return a + '0' + asHex
        } else {
          return a + asHex
        }
      }, '')
      console.log(jsonData)

      if (
        jsonData['startIndex'] !==
        detectorCtx.detectedLastError[propertyName]['currentStartIndex']
      ) {
        // TODO - maybe improve checking here?
        // something is rotten in the wonderland
        console.error(
          `[DETECTOR] Bad traceback answer '${textData}', expected start index ${detectorCtx.detectedLastError[propertyName]['currentStartIndex']}`
        )
        retry = true
      } else {
        detectorCtx.detectedLastError[propertyName]['fragments'][
          jsonData['startIndex']
        ] = jsonData

        if (jsonData['stopIndex'] === jsonData['totalLength'] - 1) {
          // received the last fragment
          let hexData = ''
          const step =
            detectorCtx.detectedLastError[propertyName]['readoutStep'] + 1 // the indices are inclusive!
          const fragments =
            detectorCtx.detectedLastError[propertyName]['fragments']
          for (
            let startIndex = 0;
            !!fragments[startIndex];
            startIndex += step
          ) {
            hexData += fragments[startIndex]['hex']
          }

          detectorCtx.detectedLastError[propertyName]['hex'] = hexData
          console.warn(hexData)
          console.dir(detectorCtx.detectedLastError[propertyName])

          // const reportedLength = parseInt(hexData.slice(2, 4) + hexData.slice(0, 2), 16);
          const reportStorageType = String.fromCharCode(
            parseInt(hexData.slice(4, 6), 16)
          )
          const fullReport = hexData.slice(6)

          if (reportStorageType === 'C') {
            // compressed storage type
            let compressed = []
            for (let i = 0; i < fullReport.length; i += 2) {
              compressed.push(parseInt(fullReport.slice(i, i + 2), 16))
            }

            const decompressed = decompress(compressed)
            const asText = new TextDecoder().decode(decompressed)
            detectorCtx.detectedLastError[propertyName]['report'] = asText
          } else {
            // direct ASCII!

            let report = ''
            for (let i = 0; i < fullReport.length; i += 2) {
              report += String.fromCharCode(
                parseInt(fullReport.slice(i, i + 2), 16)
              )
            }
            detectorCtx.detectedLastError[propertyName]['report'] = report
          }

          // TOO BIG..
          //showNotification("detectorState"+propertyName, detectorCtx.detectedLastError[propertyName]["report"],
          //    "debug-stop", NotificationLevel.WARNING, undefined, false);

          if (nextPropertyName) {
            await requestLastErrorTypeSendHelper(
              port,
              nextTypeName,
              detectorCtx.detectedLastError[nextPropertyName][
                'currentStartIndex'
              ],
              detectorCtx.detectedLastError[nextPropertyName][
                'currentStartIndex'
              ] + detectorCtx.detectedLastError[nextPropertyName]['readoutStep']
            )
          }

          detectorCtx.state = nextState
        } else {
          detectorCtx.detectedLastError[propertyName]['currentStartIndex'] +=
            detectorCtx.detectedLastError[propertyName]['readoutStep'] + 1 // the indices are inclusive!
          detectorCtx.requestTimestamp = now
          await requestLastErrorTypeSendHelper(
            port,
            typeName,
            detectorCtx.detectedLastError[propertyName]['currentStartIndex'],
            detectorCtx.detectedLastError[propertyName]['currentStartIndex'] +
              detectorCtx.detectedLastError[propertyName]['readoutStep']
          )
        }
      }
    }
  }

  if (
    now - detectorCtx.requestTimestamp >= detectorCtx.requestTimeout ||
    retry
  ) {
    if (detectorCtx.requestTrial >= REQUEST_MAX_TRIALS) {
      detectorCtx.detectedLastError = {} // could not read last error
      detectorCtx.state = DETECTOR_STATE.REQUEST_LUA_INFO
    } else {
      detectorCtx.requestTimestamp = now
      await requestLastErrorTypeSendHelper(
        port,
        typeName,
        detectorCtx.detectedLastError['traceback']['currentStartIndex'],
        detectorCtx.detectedLastError['traceback']['currentStartIndex'] +
          detectorCtx.detectedLastError['traceback']['readoutStep']
      )
      detectorCtx.requestTrial += 1
    }
  }
}

const detectStateFSM = async (
  port,
  event: EVENT_TYPE,
  onFinishedCallback: Function,
  data?
) => {
  const now = new Date().valueOf()

  if (event === EVENT_TYPE.ON_RECEIVED) {
    detectorCtx.lastRxTimestamp = now
    detectorCtx.rxBuffer.push(...data)
  }

  const elapsedSinceLastRx = now - detectorCtx.lastRxTimestamp

  switch (detectorCtx.state) {
    case DETECTOR_STATE.IDLE:
      startDetector()
      break
    case DETECTOR_STATE.SEND_NOOP:
      
      // Should not stop trying to connect by reaching TOTAL_DETECTOR_TIMEOUT

      // if (now - detectorCtx.startDetectorTimestamp >= TOTAL_DETECTOR_TIMEOUT) {
      //   // show fancy notification - did not found anything!
      //   showNotification(
      //     'detectorState',
      //     `No device detected! Connect and press & hold the button...`,
      //     'debug-stop',
      //     NotificationLevel.WARNING
      //   )
      //   detectorCtx.state = DETECTOR_STATE.DONE
      //   onFinishedCallback(DEVICE_STATE.SERIAL_PORT_CLOSED)
      // } else 
      {
        if (detectorCtx.warningNotShown) {
          if (
            now - detectorCtx.startDetectorTimestamp >=
            DETECTOR_SHOW_NOTIFICATION_TIMEOUT
          ) {
            detectorCtx.warningNotShown = false
            showNotification(
              'detectorState',
              `No device detected! Connect and press & hold the button...`,
              'debug-stop',
              NotificationLevel.WARNING,
              false,
              false
            )
          }
        }

        detectorCtx.sentNoopTimestamp = now
        detectorCtx.state = DETECTOR_STATE.LOOK_FOR_ACK_DOTS_BOOT
        await sendFrame(FrameTypes.NOOP, null, false, port.current)
      }
      break

    case DETECTOR_STATE.LOOK_FOR_ACK_DOTS_BOOT:
      if (now - detectorCtx.sentNoopTimestamp > ACK_DOTS_BOOT_TIMEOUT) {
        if (detectorCtx.tryDetectNg) {
          detectorCtx.startNgFSMTimestamp = now

          await serialBootloaderSendBootCommand(port)
          detectorCtx.state = DETECTOR_STATE.WAIT_FOR_NG_BL
        } else {
          detectorCtx.state = DETECTOR_STATE.SEND_NOOP
        }
      } else {
        while (true) {
          const rxByte = detectorCtx.rxBuffer.shift()
          if (rxByte === undefined) {
            break
          }
          if (
            detectorCtx.ackPattern[detectorCtx.ackPatternPosition] === rxByte
          ) {
            detectorCtx.ackPatternPosition += 1
            if (
              detectorCtx.ackPatternPosition >= detectorCtx.ackPattern.length
            ) {
              showNotification(
                'detectorState',
                `Application firmware detected, connecting...`,
                'debug-continue'
              )
              detectorCtx.state = DETECTOR_STATE.REQUEST_DEV_TYPE
              detectorCtx.requestTimestamp = now
              detectorCtx.rxBuffer = []
              detectorCtx.lastRxTimestamp = now
              detectorCtx.requestTrial = 0
              detectorCtx.literalAnswers = [
                `ACK${FrameTypes.SHOW_MODEL_STRING.id}_`,
              ]
              await sendFrame(
                FrameTypes.SHOW_MODEL_STRING,
                null,
                false,
                port.current
              )
            }
          } else {
            detectorCtx.ackPatternPosition = 0
          }

          if (detectorCtx.tryDetectLegacy) {
            if (
              detectorCtx.bootPattern.charCodeAt(
                detectorCtx.bootPatternPosition
              ) === rxByte
            ) {
              detectorCtx.bootPatternPosition += 1
              if (
                detectorCtx.bootPatternPosition >=
                detectorCtx.bootPattern.length
              ) {
                detectorCtx.bootPatternPosition = 0
                detectorCtx.warningNotShown = false
                //show fancy notification - press button to skip NB-IoT bootloader!
                showNotification(
                  'detectorState',
                  `NB-IoT bootloader running, hold the button to skip...`,
                  'debug-continue',
                  NotificationLevel.WARNING,
                  false,
                  false
                )
                detectorCtx.state = DETECTOR_STATE.SEND_NOOP
                detectorCtx.tryDetectNg = false
              }
            } else {
              detectorCtx.bootPatternPosition = 0
            }

            if (
              detectorCtx.dotsPattern.charCodeAt(
                detectorCtx.dotsPatternPosition
              ) === rxByte
            ) {
              detectorCtx.dotsPatternPosition += 1
              if (
                detectorCtx.dotsPatternPosition >=
                detectorCtx.dotsPattern.length
              ) {
                detectorCtx.dotsPatternPosition = 0
                detectorCtx.dotsPatternDetectedCount += 1
                detectorCtx.tryDetectNg = false
                if (
                  detectorCtx.dotsPatternDetectedCount >=
                  DOTS_DETECTED_COUNT_TRESHOLD
                ) {
                  detectorCtx.warningNotShown = false

                  detectorCtx.legacy = true
                  showNotification(
                    'detectorState',
                    `No firmware available and legacy bootloader detected`,
                    'debug-continue'
                  )


                  detectorCtx.state = DETECTOR_STATE.DONE
                  onFinishedCallback(DEVICE_STATE.BL_ONLY)
                } else {

                  detectorCtx.legacy = true
                  detectorCtx.warningNotShown = false
                  showNotification(
                    'detectorState',
                    `Legacy bootloader detected...`,
                    'debug-continue',
                    undefined,
                    false,
                    false
                  )
                  detectorCtx.state = DETECTOR_STATE.SEND_NOOP
                }
              }
            } else {
              detectorCtx.dotsPatternPosition = 0
            }
          }
        }
      }

      break
    case DETECTOR_STATE.WAIT_FOR_NG_BL:
      const receivedBeacon = await serialBootloaderFSM(
        port,
        event,
        onFinishedCallback,
        data
      )
      if (receivedBeacon) {
        NG_BL_TRY_BEACON_TIMEOUT = NG_BL_TRY_BEACON_TIMEOUT_MIN
        detectorCtx.tryDetectLegacy = false
        detectorCtx.ngBeaconReceivedCount += 1
        if (detectorCtx.ngBeaconReceivedCount >= NG_BEACON_COUNT_TRESHOLD) {
          showNotification(
            'detectorState',
            `No firmware available`,
            'debug-continue'
          )
          detectorCtx.state = DETECTOR_STATE.DONE
          onFinishedCallback(DEVICE_STATE.NG_BL_ONLY)
        } else {
          showNotification(
            'detectorState',
            `Trying to boot...`,
            'debug-continue',
            undefined,
            false,
            false
          )
        }
      } else {
        if (now - detectorCtx.startNgFSMTimestamp >= NG_BL_TRY_BEACON_TIMEOUT) {
          NG_BL_TRY_BEACON_TIMEOUT += ACK_DOTS_BOOT_TIMEOUT
          if (NG_BL_TRY_BEACON_TIMEOUT >= NG_BL_TRY_BEACON_TIMEOUT_MAX) {
            NG_BL_TRY_BEACON_TIMEOUT = NG_BL_TRY_BEACON_TIMEOUT_MIN
          }
          await port.current.switchBaudrate(ACR_BAUDRATES.COMMUNICATION)
          detectorCtx.state = DETECTOR_STATE.SEND_NOOP
        }
      }
      break

    case DETECTOR_STATE.REQUEST_DEV_TYPE:
      {
        let skip = false
        if (
          elapsedSinceLastRx >= REQUEST_TIMEOUT_NO_RX ||
          now - detectorCtx.requestTimestamp >= REQUEST_TIMEOUT
        ) {
          if (detectorCtx.requestTrial >= REQUEST_MAX_TRIALS) {
            skip = true
          } else {
            detectorCtx.requestTimestamp = now
            await sendFrame(
              FrameTypes.SHOW_MODEL_STRING,
              null,
              false,
              port.current
            )
            detectorCtx.requestTrial += 1
          }
        } else {
          const ret = AckReceptionHelper(true)
          if (ret['done']) {
            if (!ret['result']) {
              skip = true
            } else {
              // we received ACK, now parse the data!
              let devType = bufferToStringHelper(ret['data'], 1)
              detectorCtx.detectedDevType = devType
              showNotification(
                'detectorStateDevType',
                'Device Type: ' + devType,
                undefined,
                NotificationLevel.WARNING
              )
              console.log(`%c${detectorCtx.detectedDevType}`, 'color: #79f23c')
              detectorCtx.state = DETECTOR_STATE.REQUEST_DEV_VERSION
              detectorCtx.rxBuffer = []
              detectorCtx.lastRxTimestamp = now
              detectorCtx.requestTimestamp = now
              detectorCtx.requestTrial = 0
              detectorCtx.literalAnswers = [
                `ACK${FrameTypes.SHOW_FW_VERSION.id}_`,
              ]
              await sendFrame(
                FrameTypes.SHOW_FW_VERSION,
                null,
                false,
                port.current
              )
            }
          }
        }

        if (skip) {
          // if nothing was found, then give up looking for the rest
          // since it is likely the unit does not support it and directly
          // jump to connected state directly providing empty values
          detectorCtx.state = DETECTOR_STATE.DONE
          detectorCtx.luaGlobals = {}
          detectorCtx.detectedDevType = 'ACR-CV' // if nothing found, provide default value for old firmwares
          detectorCtx.detectedFirmwareVersion = 'LEGACY_CV_FW_2.7.0_and_older'
        }
      }

      break

    case DETECTOR_STATE.REQUEST_DEV_VERSION: {
      let skip = false
      if (
        elapsedSinceLastRx >= REQUEST_TIMEOUT_NO_RX ||
        now - detectorCtx.requestTimestamp >= REQUEST_TIMEOUT
      ) {
        if (detectorCtx.requestTrial >= REQUEST_MAX_TRIALS) {
          skip = true
        } else {
          detectorCtx.requestTimestamp = now
          await sendFrame(FrameTypes.SHOW_FW_VERSION, null, false, port.current)
          detectorCtx.requestTrial += 1
        }
      } else {
        const ret = AckReceptionHelper(true)
        if (ret['done']) {
          if (!ret['result']) {
            skip = true
          } else {
            // we received ACK, now parse the data!
            let devVersion = bufferToStringHelper(ret['data'], 1)
            detectorCtx.detectedFirmwareVersion = devVersion // answer is in format ACKxx_the-dev-version
            showNotification(
              'detectorStateFwVersion',
              'Firmware Version: ' + devVersion,
              undefined,
              NotificationLevel.WARNING
            )
            console.log(
              `%c${detectorCtx.detectedFirmwareVersion}`,
              'color: #79f23c'
            )

            let doRequestLastError = false
            try {
              let versionNumbers = devVersion
                .split('CV_FW_')[1]
                .split('-')[0]
                .split('.')
              if (
                parseInt(versionNumbers[0]) >= 2 &&
                parseInt(versionNumbers[1]) >= 8
              ) {
                doRequestLastError = false //! false for now, to speed up thing, enable and test and fix later! TODO FIXME HALP
              } else {
                doRequestLastError = false
              }
            } catch (e) {
              doRequestLastError = false
            }

            if (doRequestLastError) {
              detectorCtx.state = DETECTOR_STATE.REQUEST_LAST_ERR
              detectorCtx.requestTimestamp = now
              await requestLastErrorShortSendHelper(port)
            } else {
              detectorCtx.luaGlobals = {}
              detectorCtx.state = DETECTOR_STATE.DONE
            }
          }
        }
      }

      if (skip) {
        // if nothing was found, then give up looking for the rest
        // since it is likely the unit does not support it and directly
        // jump to connected state directly providing empty values
        detectorCtx.luaGlobals = {}
        detectorCtx.state = DETECTOR_STATE.DONE
        detectorCtx.detectedFirmwareVersion = '' // if nothing found, provide empty string
      }

      break
    }
    case DETECTOR_STATE.REQUEST_LAST_ERR:
      await acrComReceiverFSM(port, event, data)
      if (acrComReceiverGetState() === ACRCOM_RECEIVER_STATE.RECEIVED) {
        const ansData = acrComReceiverGetReceivedFrame()
        console.log(ansData) // TODO, unmarshall to detectorCtx.detectedLastError object
        let errors = new TextDecoder().decode(new Uint8Array(ansData.slice(3)))
        if (errors === 'ACK') {
          showNotification(
            'detectorStateCrashDetected',
            'Checking last error...',
            undefined,
            NotificationLevel.WARNING
          )
          detectorCtx.requestTimeout = 6 * REQUEST_TIMEOUT
          detectorCtx.requestTimestamp = now
          acrComReceiverStart()
        } else {
          console.log(errors)
          let errorsObj = JSON.parse(errors)
          console.log(errorsObj)
          detectorCtx.detectedLastError = errorsObj

          if (errorsObj['short']?.trim()?.length > 0) {
            showNotification(
              'detectorStateCrashDetected',
              'Last Lua error: <' + errorsObj['short'].trim() + '>',
              undefined,
              NotificationLevel.WARNING
            )

            if (detectorCtx.disableReadingErrorDetails) {
              detectorCtx.state = DETECTOR_STATE.REQUEST_LUA_INFO
            } else {
              showNotification(
                'detectorState',
                `Last Lua error detected, reading details...`,
                'debug-stop',
                NotificationLevel.WARNING
              )

              // has error report - get details
              detectorCtx.detectedLastError['traceback'] = {}
              detectorCtx.detectedLastError['traceback'][
                'currentStartIndex'
              ] = 0
              detectorCtx.detectedLastError['traceback']['readoutStep'] = 2048
              detectorCtx.detectedLastError['traceback']['fragments'] = {}
              detectorCtx.detectedLastError['stdout'] = {}
              detectorCtx.detectedLastError['stdout']['currentStartIndex'] = 0
              detectorCtx.detectedLastError['stdout']['readoutStep'] = 2048
              detectorCtx.detectedLastError['stdout']['fragments'] = {}
              detectorCtx.state = DETECTOR_STATE.REQUEST_LAST_ERR_TRACEBACK
              detectorCtx.requestTimestamp = now
              await requestLastErrorTypeSendHelper(
                port,
                'TRACEBACK_RAW',
                detectorCtx.detectedLastError['traceback']['currentStartIndex'],
                detectorCtx.detectedLastError['traceback'][
                  'currentStartIndex'
                ] + detectorCtx.detectedLastError['traceback']['readoutStep']
              )
            }
          } else {
            // no error, go directly to LUA_INFO request
            detectorCtx.state = DETECTOR_STATE.REQUEST_LUA_INFO
          }
        }
      } else {
        if (now - detectorCtx.requestTimestamp >= detectorCtx.requestTimeout) {
          if (detectorCtx.requestTrial >= REQUEST_MAX_TRIALS) {
            detectorCtx.detectedLastError = {} // could not read last error
            detectorCtx.state = DETECTOR_STATE.REQUEST_LUA_INFO
          } else {
            detectorCtx.requestTimestamp = now
            await requestLastErrorShortSendHelper(port)
            detectorCtx.requestTrial += 1
          }
        }
      }
      break

    case DETECTOR_STATE.REQUEST_LAST_ERR_TRACEBACK:
      await processRequestLastErrorState(
        port,
        now,
        event,
        data,
        'TRACEBACK_RAW',
        DETECTOR_STATE.REQUEST_LAST_ERR_STDOUT,
        'traceback',
        'stdout',
        'STDOUT_RAW'
      )
      break

    case DETECTOR_STATE.REQUEST_LAST_ERR_STDOUT:
      await processRequestLastErrorState(
        port,
        now,
        event,
        data,
        'STDOUT_RAW',
        DETECTOR_STATE.REQUEST_LUA_INFO,
        'stdout'
      )
      break

    case DETECTOR_STATE.REQUEST_LUA_INFO:
      if (detectorCtx.disableReadingLuaInfo) {
        detectorCtx.luaGlobals = {} // could not read lua globals
        detectorCtx.state = DETECTOR_STATE.DONE
        break
      }

      // readout _G table from Lua using same approach as above
      detectorCtx.requestTimestamp = now
      detectorCtx.requestTrial = 0
      await requestGlobalsSendHelper(port)
      detectorCtx.state = DETECTOR_STATE.REQUEST_LUA_INFO_WAIT_FOR_ANSWER
      break

    case DETECTOR_STATE.REQUEST_LUA_INFO_WAIT_FOR_ANSWER:
      await acrComReceiverFSM(port, event, data)
      if (acrComReceiverGetState() === ACRCOM_RECEIVER_STATE.RECEIVED) {
        const ansData = acrComReceiverGetReceivedFrame()
        console.log(ansData) // TODO, unmarshall to detectorCtx.detectedLastError object
        let luaGlobalsText = new TextDecoder().decode(
          new Uint8Array(ansData.slice(3))
        )
        if (luaGlobalsText === 'ACK') {
          showNotification('luaInfo', `Loading Lua globals list...`)
          detectorCtx.requestTimeout = 15 * REQUEST_TIMEOUT
          detectorCtx.requestTimestamp = now
          acrComReceiverStart()
        } else {
          console.log(luaGlobalsText)
          let luaGlobalsObj = JSON.parse(luaGlobalsText)
          console.log(luaGlobalsObj)
          detectorCtx.luaGlobals = luaGlobalsObj

          showNotification('luaInfo', `Lua globals list loaded...`)
          detectorCtx.state = DETECTOR_STATE.DONE
        }
      } else {
        if (now - detectorCtx.requestTimestamp >= detectorCtx.requestTimeout) {
          if (detectorCtx.requestTrial >= REQUEST_MAX_TRIALS) {
            detectorCtx.luaGlobals = {} // could not read lua globals
            detectorCtx.state = DETECTOR_STATE.DONE
          } else {
            detectorCtx.requestTimestamp = now
            await requestGlobalsSendHelper(port)
            detectorCtx.requestTrial += 1
          }
        }
      }

      break

    case DETECTOR_STATE.DONE:
      showNotification(
        'detectorState',
        `Ready!`,
        undefined,
        NotificationLevel.INFO
      )

      if (detectorCtx.detectedFirmwareVersion !== '') {
        // check if bootloader legacy by type
        let legacy = false
        const reg = new RegExp('ACR_CV_[0-9]+N')
        if (reg.test(detectorCtx.detectedDevType)) {
          legacy = true
        }

        const parsedVersion = parseFwVersion(detectorCtx.detectedFirmwareVersion)
        const deviceInfo = {
          detected: true,
          fwVersion: parsedVersion,
          type: detectorCtx.detectedDevType,
          legacy: detectorCtx.legacy || legacy
        }

        onFinishedCallback(DEVICE_STATE.CONNECTED, deviceInfo)
      }
      break
  }
}


export { startDetector, detectStateFSM }
