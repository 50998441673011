import { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Col } from 'react-bootstrap'

type FileNameModalProps = {
  show: boolean
  setShow: (show: boolean) => void
  handleSubmit: (fileName: string) => void
}

const FileNameModal: FC<FileNameModalProps> = ({
  show,
  setShow,
  handleSubmit,
}) => {
  const [fileName, setFileName] = useState('newFile.lua')

  const onSubmit = (event) => {
    event.preventDefault()
    handleSubmit(fileName)
  }

  return (
    <Modal
      show={show}
      data-bs-theme="dark"
      className="acrios-modal"
      backdrop="static"
      onHide={() => {
        setShow(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Col xs={7}>
            <Form.Group className="mb-1" controlId="file-name">
              <Form.Label>File Name</Form.Label>
              <Form.Control
              required
              placeholder="File Name"
              value={fileName}
              onChange={(event) => setFileName(event.target.value)}
              className="mt-1"
            />
            </Form.Group>
          </Col>
          <Button type="submit" disabled={!fileName} className="mt-3">
            Create
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default FileNameModal
