import { localize } from '@dtinsight/molecule/esm/i18n/localize'
import { IPanelItem } from '@dtinsight/molecule/esm/model'
import { TerminalPane } from '../../views/terminal/terminalPanelView'

export const TERMINAL_ID = 'terminalID'

export const terminalPanel: IPanelItem = {
  id: TERMINAL_ID,
  active: true,
  name: localize('demo.terminal', 'Interactive Mode'),
  title: 'Interactive Mode',
  sortIndex: 10,
  closable: false,
  renderPane: () => {
    return <TerminalPane />
  },
}
