import React, { useContext, useEffect, useState } from 'react'
import { useLocalStorage } from '../hooks'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import { LuaWriteParams } from 'src/types'
import LuaCompiler from 'src/api/luac/luac'
import molecule from '@dtinsight/molecule'
import { prepareWriteLuaPayload } from 'src/api/serialApi'
import { SerialContext, SerialContextType } from 'src/api/serialProvider'
import {
  NotificationLevel,
  showNotification,
  showProblemsPanel,
} from 'src/common'
import { useGlobalState } from '../state/state'
import { lesserEqual } from 'src/utils/versionUtils'

type LuaWriteModalProps = {
  show: boolean
  onHide: () => void
  onWrite: (payload) => void
}

const LuaWriteModal: React.FC<LuaWriteModalProps> = ({
  show,
  onHide,
  onWrite,
}) => {
  const [encryptScript, setEncryptScript, saveEncryptScript] = useLocalStorage(
    'encryptScript',
    false
  )
  const [withDebugSymbols, setWithDebugSymbols, saveWithDebugSymbols] =
    useLocalStorage('withDebugSymbols', false)
  const [withoutScript, setWithoutScript, saveWithoutScript] = useLocalStorage(
    'withoutScript',
    false
  )

  const DEFAULT_PASSWORD = 'unbreakable'
  const [password, setPassword, savePassword] = useLocalStorage('password', '')

  const [canWrite, setCanWrite] = useState(false)
  const [canUseEncrypt, setCanUseEncrpyt] = useState(false)
  const [payloadSizeBytes, setpayloadSize] = useState(0)
  const { deviceInfo } = useContext(SerialContext) as SerialContextType

  const [payload, setPayload] = useState(null)

  const [isError, setIsError] = useState(false)

  const preCompileScript = async (params: LuaWriteParams) => {
    const tab = molecule.editor.getState()?.current?.tab
    const input = tab?.data?.value
    LuaCompiler({
      debugSymbols: params.withDebugSymbols,
      input,
      //Callback that processes sucessfull output
      callback: async (compiledScript) => {
        showNotification(
          'luaCompiler',
          `Code "${tab.name}" compiled sucessfully!`
        )

        const compilerOutput = await prepareWriteLuaPayload(
          compiledScript,
          input,
          deviceInfo.current.fwVersion,
          params
        )
        // size is rounded to 128 bytes
        const size = Math.ceil(compilerOutput.length / 128) * 128
        setpayloadSize(size)
        setPayload(compilerOutput.frames)

        // we want to compile right before write if there is other than default pass
        if (params.encryptScript && params.password != DEFAULT_PASSWORD) {
          onWrite(compilerOutput.frames)
        } else {
          setPayload(compilerOutput.frames)
        }
      },
      //Callback for stdout (Usually for handling the compilation issue)
      stdout: (output) => {
        // parse line number and error
        // eslint-disable-next-line
        const [_, line, error] = /::(\d*):\s(.*)/.exec(output) ?? []
        const lineNum = parseInt(line, 10)

        showNotification(
          'luaCompiler',
          `Error while compiling "${tab.name}": ${error ?? output}\n`,
          undefined,
          NotificationLevel.ERROR
        )

        molecule.problems.add({
          id: 1,
          name: tab.name,
          isLeaf: true,
          value: {
            code: error ?? output,
            message: tab.name,
            startLineNumber: lineNum ?? 0,
            startColumn: 1,
            endLineNumber: lineNum ?? 0,
            endColumn: 1,
            status: 1,
          },
          children: [],
        })
        showProblemsPanel()
        setCanWrite(false)
        setIsError(true)

        // TODO: highlight line with error
      },
    })
  }

  const saveToLocalStorage = () => {
    saveEncryptScript()
    saveWithDebugSymbols()
    saveWithoutScript()
    savePassword()
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (encryptScript && (password != '' || password != DEFAULT_PASSWORD)) {
      await preCompileScript({
        password: password,
        encryptScript: true,
        withDebugSymbols: withDebugSymbols,
        withoutScript: false,
      })
    } else {
      onWrite(payload)
    }

    onHide()
  }

  useEffect(() => {
    preCompileScript({
      encryptScript,
      password: DEFAULT_PASSWORD,
      withDebugSymbols,
      withoutScript,
    })
  }, [encryptScript, withDebugSymbols, withoutScript])

  useEffect(() => {
    setCanWrite(payloadSizeBytes <= deviceCapacityBytes && !isError)
  }, [payloadSizeBytes, isError])

  const [deviceFwVersion, _setDeviceFwVersion] =
    useGlobalState('deviceFwVersion')
  useEffect(() => {
    if (lesserEqual(deviceFwVersion, { major: 2, minor: 13, patch: 0 })) {
      setCanUseEncrpyt(false)
    } else setCanUseEncrpyt(true)
  }, [deviceFwVersion])

  const [deviceType, _setDeviceType] = useGlobalState('deviceType')
  const deviceTypeArr = deviceType.split('_')
  const model = deviceTypeArr[2]
  const deviceCapacityBytes = model.endsWith('L')
    ? 24 * 1024 // Lora
    : model.endsWith('N') // NbIoT
    ? 32 * 1024
    : 0
  const storagePercentage = (payloadSizeBytes / deviceCapacityBytes) * 100

  return (
    <Modal
      show={show}
      onHide={onHide}
      data-bs-theme="dark"
      className="acrios-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Write Lua Script</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Check
            type="checkbox"
            label="With Debug Symbols"
            checked={withDebugSymbols}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setWithDebugSymbols(event.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Write Only Compiled Script"
            disabled={encryptScript}
            checked={withoutScript}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setWithoutScript(event.target.checked)
            }
          />
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Encypt Script With Password"
              disabled={!canUseEncrypt}
              checked={encryptScript && canUseEncrypt}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const encrypted = event.target.checked
                setEncryptScript(encrypted)
                if (encrypted && withoutScript) {
                  setWithoutScript(false)
                }
              }}
            />
            {encryptScript && (
              <Form.Control
                required
                placeholder="Password"
                value={password}
                disabled={!encryptScript}
                onChange={(event) => setPassword(event.target.value)}
                className="mt-2"
              />
            )}
          </Form.Group>
          <Row className="align-items-center mt-3">
            <Col className="align-self-end">
              <p className="text-muted mb-1">Device: {deviceType}</p>
              <p className="text-muted">
                Firmware version: {deviceFwVersion.major}.
                {deviceFwVersion.minor}.{deviceFwVersion.patch}
              </p>
              <Stack direction="horizontal" gap={3}>
                <Button type="submit" disabled={!canWrite}>
                  Write
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={saveToLocalStorage}
                >
                  Save
                </Button>
              </Stack>
            </Col>
            <Col className="text-end align-self-end">
              {isError ? (
                <p className="m-0 text-danger">Cannot compile script</p>
              ) : isNaN(storagePercentage) ? (
                <p className="m-0 text-warning">There is a problem with the payload. Writing may not complete successfully!</p>
              ) : (
                <p className="m-0 text-muted">
                  Load: {(payloadSizeBytes / 1024).toFixed(2)}kB /{' '}
                  {deviceCapacityBytes / 1024}kB <br /> Memory usage:{' '}
                  <span
                    className={
                      payloadSizeBytes > deviceCapacityBytes
                        ? 'text-danger'
                        : storagePercentage > 90
                        ? 'text-warning'
                        : 'text-success'
                    }
                  >
                    {storagePercentage.toFixed(2)}%
                  </span>
                </p>
              )}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default LuaWriteModal
