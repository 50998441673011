import React, { useEffect } from 'react'

import 'xterm/css/xterm.css'
import { FitAddon } from 'xterm-addon-fit'

export const TerminalPane = () => {
  useEffect(() => {
    const term = (window as any).term
    term.open(document.getElementById('terminalDiv'))

    // fit to window
    const fitAddon = new FitAddon()
    term.loadAddon(fitAddon)
    fitAddon.fit()
  }, [])

  return (
    <div style={{ padding: '5px 0px 30px 15px' }}>
      <div id={'terminalDiv'}></div>
    </div>
  )
}
