import { WikiApiData, LuaApiFunction } from 'src/types'
import { FW_VERSION } from './fwVersion'

const apiJsonURL = 'https://wiking.acrios.com/apiExport.json'

// Fetch and save Json containing API functions
export const fetchAPIData = async () => {
  try {
    const response = await fetch(apiJsonURL, {
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) throw new Error('API data not found')
    const data: WikiApiData = await response.json()
    localStorage.setItem('luaApiData', JSON.stringify(data)) // Saving to local storage
  } catch (error) {
    console.error('Error fetching API data:', error)
  }
}

// Get API functions based on firmware version
export const getAPIFunctionsForVersion = (
  deviceFW: FW_VERSION
): LuaApiFunction[] => {
  const apiData: WikiApiData | null = JSON.parse(
    localStorage.getItem('luaApiData')
  )
  if (!apiData) return []

  // convert string in version object to number
  apiData.forEach((data) => {
    data.version.major = parseInt(data.version.major as any)
    data.version.minor = parseInt(data.version.minor as any)
    data.version.patch = parseInt(data.version.patch as any)
  })

  // select highest version that is lower or equal to firmware version
  const compatibeVersions = apiData
    .filter((data) => {
      const apiFW = data.version
      if (apiFW.major < deviceFW.major) return true
      if (apiFW.major == deviceFW.major) {
        if (apiFW.minor < deviceFW.minor) return true
        if (apiFW.minor == deviceFW.minor) {
          return apiFW.patch <= deviceFW.patch
        }
      }
      return false
    })
    .sort((a, b) => {
      const aVersion = a.version
      const bVersion = b.version
      return (
        bVersion.major - aVersion.major ||
        bVersion.minor - aVersion.minor ||
        bVersion.patch - aVersion.patch
      )
    })
    .reverse()

  const selectedVersion = compatibeVersions.pop()?.api

  return selectedVersion || []
}

export const getLatestAPIVersion = (): FW_VERSION | null => {
  const apiData: WikiApiData | null = JSON.parse(
    localStorage.getItem('luaApiData')
  )
  if (!apiData) return null

  apiData.forEach((data) => {
    data.version.major = parseInt(data.version.major as any)
    data.version.minor = parseInt(data.version.minor as any)
    data.version.patch = parseInt(data.version.patch as any)
  })

  const latestVersion = apiData
    .sort((a, b) => {
      const aVersion = a.version
      const bVersion = b.version
      return (
        bVersion.major - aVersion.major ||
        bVersion.minor - aVersion.minor ||
        bVersion.patch - aVersion.patch
      )
    })
    .reverse()
    .pop()?.version

  return latestVersion || null
}
