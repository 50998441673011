import grammar from "./grammar"
import * as nearley from "nearley"

/**
 * Parses LUA script file
 */
export const parse = (file: string) => {
  const errors: Array<{line: number, error: string, col: number}> = []

  try {
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(
      grammar as nearley.CompiledRules),
      { keepHistory: true }
    )

    parser.feed(file)

    // grammar should generate 1 AST 
    if (parser.results.length != 1) {
      errors.push({ error: "Analysis error! Please contact support", line: 0, col: 0})
    
      // TODO: automatically send error report
    }
  } catch (err) {
    if (err['error']) {
      console.error(err)
      // semantic error (thrown in post-processing)
      errors.push(err)
    } else {
      // syntax error (Error object)
      const errStr = err.toString()
      const parts = errStr.split(" ")
      let line = 0
      let col = 0
      if (parts[5] != undefined && parts[7] != undefined) {
        line = Number(parts[5])
        col = Number(parts[7])
      }
      
      const error = { error: errStr, line: line, col: col }
      console.error(error)
      errors.push(error)
    }
  }

  return errors 
}