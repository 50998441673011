import { useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

type ITutorialModal = {
  close: () => void
}

/**
 * Modal window for tutorial how to use Acrios IDE
 */
const TutorialModal = (props: ITutorialModal) => {
  const ref = useRef(null)
  useOnClickOutside(ref, props.close)

  const video1 = '_pHgILVlx3c'
  const video4 = 'zFPtST3wuGk'

  return (
    <div
      ref={ref}
      style={{
        backgroundColor: '#1e1e1e',
        width: '80%',
        height: '80%',
        color: '#ccc',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'monospace',
      }}
    >
      <h1>Acrios IDE Tutorial</h1>

      <p>
        Welcome to the Acrios Integrated Development Environment (IDE), a
        Graphical User Interface designed for editing and managing LUA scripts
        in your Acrios device. This tutorial will guide you through the main
        features of Acrios IDE, helping you to effectively use this platform for
        your development needs.
      </p>

      <div
        style={{
          flexGrow: '1',
          overflow: 'auto',
          paddingRight: '1rem',
        }}
      >
        <div style={{ padding: '1rem 0', display: 'flex' }}>
          <iframe
            style={{ margin: 'auto' }}
            title="YouTube Video"
            width="640"
            height="390"
            src={`https://www.youtube.com/embed/${video1}`}
            frameBorder="0"
            allowFullScreen
          />
        </div>

        <section>
          <header>
            <h2>Main Menu Features</h2>
            <p>
              The main menu contains various button for interacting with the
              device.
            </p>
          </header>

          <ul style={{ fontSize: '1em' }}>
            <li>
              <h4>Connect/Disconnect</h4>
              <ul>
                <li>
                  Function: Opens or closes a serial port requested from the
                  browser. This port is used to communicate with the Acrios
                  device.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Config</h4>
              <ul>
                <li>
                  Function: Attempts to connect to the device when it's
                  connected via a serial cable.
                </li>
                <li>
                  Usage: Ensure the device is connected and click this button.
                </li>
                <li>
                  Note: In some cases, for example, when the device is in sleep
                  mode, you may need to hold the button on the device when
                  pressing the Config button.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Read</h4>
              <ul>
                <li>
                  Function: Reads LUA script stored in the device and shows it
                  in the editor.
                </li>
                <li>
                  Usage: Click to read the script. If it's encrypted, a dialog
                  will appear to enter the password.
                </li>
                <li>Note: Only works for devices with readable scripts.</li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Write</h4>
              <ul>
                <li>
                  Function: Writes the currently open LUA script to the device.
                </li>
                <li>
                  Options:
                  <ul>
                    <li>
                      With Debug Symbols: Write compiled script along with the
                      debug symbols.
                    </li>
                    <li>
                      Write Only Compiled Script: Write only the compiled script, not the uncompiled
                      plaintext (you won't be able to retrieve script using the
                      Read operation).
                    </li>
                    <li>
                      Encrypt Script: Encrypt the plaintext script with a
                      password.
                    </li>
                  </ul>
                </li>
                <li>
                  Usage: Choose the appropriate options based on your needs.
                </li>
                <li>
                  Note: Options have impact on the size of the write payload.
                  Payload size is calculated and compared with device capacity.
                  In larger scripts, you may not have the possiblity to write
                  them with debug symbols. The button "Save" saves the options
                  so the next time the the dialog is shown, the options are
                  preselected.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Validate</h4>
              <ul>
                <li>
                  Function: Checks if the currently open script can be compiled.
                </li>
                <li>
                  Usage: Click to validate if your script is correct and can be
                  written to the device.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Interactive Mode</h4>
              <ul>
                <li>
                  Function: Opens an interactive console for calling LUA API
                  functions of the device.
                </li>
                <li>
                  Usage: Click the button to open the console. Type in the
                  command and press Enter to execute it. The console includes
                  autocompletion for API functions based on device type and
                  firmware version. Type command (for example "api.") and use
                  "Tab" to show suggestions.
                </li>
                <li>
                  Important:{' '}
                  <span className="text-danger">
                    Exit interactive mode before disconnecting the device.
                  </span>
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Update Firmware (FW)</h4>
              <ul>
                <li>Function: Updates the firmware of the device.</li>
                <li>
                  Usage: Click when you need to upgrade your device's firmware.
                  A dialog will appear to select the firmware file.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Device Info</h4>
              <ul>
                <li>
                  Function: Displays information about the connected device.
                </li>
                <li>
                  Usage: Click to view details like device type, firmware
                  version (indicating whether the newer version is available),
                  or device state.
                </li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mt-5">
          <header>
            <h2>Additional Features</h2>
          </header>
          <ul>
            <li>
              <h4 className="mt-3">File Tree</h4>
              <ul>
                <li>
                  On the left side, you'll find an explorer with sample LUA
                  files. These can be used as templates or for testing purposes.
                </li>
              </ul>
            </li>

            <li>
              <h4 className="mt-3">Help Section</h4>
              <p className="mb-0">
                Help section can be accessed from the top menu bar.
              </p>
              <ul>
                <li>
                  Toggle Dev Features: Allows firmware updates for bricked
                  devices. Use this if the 'Connect' works but 'Config' fails.
                </li>
                <li>
                  Firmware Changelog: Shows the changelog of the firmware.
                </li>
                <li>Tutorial: Shows this tutorial.</li>
              </ul>
            </li>
          </ul>
        </section>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '2rem 0 0.5rem 0',
        }}
      >
        <p>This tutorial will be available in top menu Help-&gt;Tutorial</p>
        <button
          style={{
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: '#0e639c',
            padding: '0.4rem 1rem',
            border: '0',
          }}
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default TutorialModal
