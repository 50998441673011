const isWhatPercentOf = (numA: number, numB: number): string => {
  let percent = (numA / numB) * 100
  if (isNaN(percent) || percent < 0) {
    percent = 0
  }
  if (percent > 100) {
    percent = 100
  }
  return percent.toFixed(2)
}

const bytesToNumber = (
  buffer: any[],
  start: number = 0,
  count: number = 4
): number => {
  let number = 0

  for (let i = 0; i < count; i += 1) {
    number += (buffer[start + i] & 0xff) << (i * 8)
  }

  return number
}

const bufferToHex = (buffer: Uint8Array): string => {
  return buffer.reduce((acc, x) => {
    acc += `${x.toString(16)}:`
    return acc
  }, '')
}

const mergeUint8Arrays = (a: Uint8Array, b: Uint8Array): Uint8Array => {
  let merged = new Uint8Array(a.length + b.length)
  merged.set(a)
  merged.set(b, a.length)

  return merged
}

const formatBytes = (bytes, decimals = 4) => {
  if (bytes === 0) return '0 B'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export {
  isWhatPercentOf,
  bytesToNumber,
  bufferToHex,
  mergeUint8Arrays,
  formatBytes,
}
