import { IExtension } from '@dtinsight/molecule/esm/model'
import { TerminalExtension } from './terminal'
import { RunningExtension } from './luaCompiler'
import { SettingsExtension } from './settings'
import { MenuBarExtension } from './menubar/index'
import { AcrSamplesExtension } from './acrSamples'
import { ActionExtension } from './action'

const extensions: IExtension[] = [
  new TerminalExtension(),
  new RunningExtension(),
  new SettingsExtension(),
  new MenuBarExtension(),
  new AcrSamplesExtension(),
  new ActionExtension(),
]

export default extensions
