import molecule from '@dtinsight/molecule'
import { IExtension } from '@dtinsight/molecule/esm/model/extension'
import { IExtensionService } from '@dtinsight/molecule/esm/services'
import { LuaAction } from './base'

import LuaCompiler from '../../api/luac/luac.js'
import {
  NotificationLevel,
  showNotification,
  showOutputPanel,
  showProblemsPanel,
} from '../../common'
import { parse } from 'src/api/luac/parser/luaParser'
import { LuaWriteParams } from 'src/types'

export class RunningExtension implements IExtension {
  id: string = ''
  name: string = ''

  constructor(
    id: string = 'luaCompiler',
    name: string = 'LUA Compiler UI Extension'
  ) {
    this.id = id
    this.name = name
  }

  activate(extensionCtx: IExtensionService): void {
    this.initUI()
    this.onClickAction()

    molecule.event.EventBus.subscribe(LuaAction.VALIDATE, () => {
      this.validateOrDeploy(LuaAction.VALIDATE)
    })

    molecule.event.EventBus.subscribe(
      LuaAction.DEPLOY,
      (params: LuaWriteParams) => {
        this.validateOrDeploy(LuaAction.DEPLOY, params)
      }
    )
  }

  initUI() {}

  validateOrDeploy(
    action: LuaAction = LuaAction.VALIDATE,
    deployParams?: LuaWriteParams
  ) {
    const tab = molecule.editor.getState()?.current?.tab
    if (!tab) {
      return
    }

    // molecule.problems.reset();
    showOutputPanel()
    molecule.panel.outputEditorInstance.setValue('')

    
    if (action == LuaAction.VALIDATE) {
      const errors = parse(tab.data.value)
      if (errors.length > 0) {
        // show line of first error
        molecule.editor.editorInstance.revealLineInCenter(errors[0].line)
  
        errors.forEach((e) => console.error(e.error))
        const errStr = errors[0].error
        molecule.panel.outputEditorInstance.setValue(
          errStr
        )
        return
      }
  
    
      molecule.panel.outputEditorInstance.setValue(
        "Source code is valid!"
      )
    }

    if (action === LuaAction.DEPLOY) { 
      LuaCompiler({
        debugSymbols: deployParams?.withDebugSymbols,
        input: tab.data.value,
        //Callback that processes sucessfull output
        callback: (payload) => {
          showNotification(
            'luaCompiler',
            `Code "${tab.name}" compiled sucessfully!`
          )

          if (action === LuaAction.DEPLOY) {
            if (!deployParams) {
              throw new Error('Deploy params are not passed')
            }
            molecule.event.EventBus.emit(
              LuaAction.WRITE,
              payload,
              tab.data.value,
              deployParams
            )
          }
        },

        //Callback for stdout (Usually for handling the compilation issue)
        stdout: (output) => {
          // parse line number and error
          // eslint-disable-next-line
          const [_, line, error] = /::(\d*):\s(.*)/.exec(output) ?? []
          const lineNum = parseInt(line, 10)

          showNotification(
            'luaCompiler',
            `Error while compiling "${tab.name}": ${error ?? output}\n`,
            undefined,
            NotificationLevel.ERROR
          )

          molecule.problems.add({
            id: 1,
            name: tab.name,
            isLeaf: true,
            value: {
              code: error ?? output,
              message: tab.name,
              startLineNumber: lineNum ?? 0,
              startColumn: 1,
              endLineNumber: lineNum ?? 0,
              endColumn: 1,
              status: 1,
            },
            children: [],
          })
          showProblemsPanel()

          // TODO: highlight line with error
        },
      })
    }
  }

  onClickAction() {
    molecule.editor.onActionsClick(async (menuId, current) => {
      // check if action is not disabled
      if (current.actions.find((x) => x.id === menuId)?.disabled === true) {
        return
      }

      // TODO: implement some editor action?
    })
  }

  dispose(extensionCtx: IExtensionService): void {}
  
}
