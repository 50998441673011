import { IExtension } from '@dtinsight/molecule/esm/model/extension'
import { IExtensionService } from '@dtinsight/molecule/esm/services'

import { KeybindingAction } from './keybindingAction'
import { QuickOpenAction } from './quickOpenAction'
import { QuickCreateFile } from './quickCreateFile'

export class ActionExtension implements IExtension {
  id: string = 'actionExtension'
  name: string = 'Action Extension'

  activate(extensionCtx: IExtensionService): void {
    extensionCtx.registerAction(QuickOpenAction)
    extensionCtx.registerAction(KeybindingAction)
    extensionCtx.registerAction(QuickCreateFile)
  }

  dispose(extensionCtx: IExtensionService): void {
    throw new Error('Method not implemented.')
  }
}
