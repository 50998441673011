import { Buffer } from 'buffer'

import { sendFrame } from './serialApi'
import FrameTypes from './frameTypes'

import { EVENT_TYPE } from './serialProvider'

import { bufferToHex, isWhatPercentOf } from './helpers'
import { AcriosSerial } from './serialDriver'
import { NotificationLevel, showNotification } from '../common'

enum BOOTLOADER_STATE {
  IDLE = 'idle', // send reset, save timestamp and go to next state
  BL_DOTS = 'bl-dots', // wait for timeout and go to idle or get dots message + send erase first page command after
  WFA_ERASE_FIRST = 'wfa-erase-first', // wait for timeout, increment retry and go to idle or get acknowledge and move forward, erasing 2nd page
  WFA_ERASE = 'wfa-erase', // wait for timeout, move forward, if ack receieved by sending 2nd page write
  WFA_WRITE = 'wfa-write', // wait for timeout, move forward by erasing 3rd page and go to WFA_ERASE, if page == last page, go to WFA_PAGE_WRITE_FIRST
  WFA_WRITE_FIRST = 'wfa-write-first',
  DONE = 'done', // keep idling
  ERROR = 'error', // when things don't go well, the FSM ends here
}

const PAGE_SIZE = 2048.0

const refBootloaderCtx = {
  state: BOOTLOADER_STATE.IDLE,
  resetTimestamp: 0,
  eraseTimestamp: 0,
  retryTimestamp: 0,
  writeTimestamp: 0,
  rxBuffer: [],
  errorsInRow: 0,
  currentPage: 0,
  totalPages: 0,
  fw: [],
}

const sum = (buffer) => {
  let number = 0
  for (let i = 0; i < buffer.length; i += 1) {
    number = (number + buffer[i]) & 0xff
  }
  return number
}

const sendBootDevice = (port) => {
  sendFrame(FrameTypes.PING, null, false, port.current)
}

const sendResetDevice = (port) => {
  sendFrame(FrameTypes.RESET_BOARD, null, false, port.current)
}

const sendProgramPage = (port, pageNumber, fw, pageSize = PAGE_SIZE) => {
  const pageData = fw.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)
  let curPage

  if (pageData.length < pageSize) {
    curPage = new Uint8Array(pageSize)
    for (let i = 0; i < pageSize; i += 1) {
      curPage[i] = pageData[i] ?? 0xff
    }
  } else {
    curPage = pageData
  }

  //Format frame
  const chunks = []
  chunks.push(Buffer.from(new Uint8Array([0xa5, pageNumber & 0xff])))
  chunks.push(Buffer.from(curPage))
  chunks.push(
    Buffer.from(
      new Uint8Array([(sum(curPage) + 0xa5 + (pageNumber & 0xff)) & 0xff])
    )
  )

  const data = Buffer.concat(chunks)

  try {
    port.current.write(data)
    return true
  } catch (error) {
    console.error(
      '[SEND BL PAGE PROGRAM ERROR] Failed while writing to the port.',
      error
    )
    return false
  }
}

const sendErasePage = (port, pageNumber) => {
  //Format frame
  const chunks = []
  chunks.push(Buffer.from(new Uint8Array([0x5a, pageNumber & 0xff])))
  chunks.push(Buffer.from(new Uint8Array([0x5a + (pageNumber & 0xff)])))

  const data = Buffer.concat(chunks)

  try {
    port.current.write(data)
    return true
  } catch (error) {
    console.error(
      '[SEND BL PAGE ERASE ERROR] Failed while writing to the port.',
      error
    )
    return false
  }
}

const bootloaderStart = (fw) => {
  // cache file to upload as buffer
  refBootloaderCtx.fw = fw

  refBootloaderCtx.totalPages = Math.ceil(fw.length / PAGE_SIZE)

  // reset state machine to default state
  refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
  refBootloaderCtx.resetTimestamp = 0
  refBootloaderCtx.eraseTimestamp = 0
  refBootloaderCtx.retryTimestamp = 0
  refBootloaderCtx.rxBuffer = []
  refBootloaderCtx.errorsInRow = 0
  refBootloaderCtx.currentPage = 0

  // externally: call FSM legacySerialBootloaderFSM() function
  // in periodic tick (1 or 10 or 100 ms) tick is OK
  // in on receive event passing received bytes data as buffer in eventData
}

const bootloaderGetState = () => {
  return refBootloaderCtx.state
}

const WAIT_FOR_DOTS_TIMEOUT = 2000
const WAIT_FOR_ERASE_TIMEOUT = 3000
const WAIT_FOR_ERASE_RETRY_AFTER = 500
const WAIT_FOR_WRITE_TIMEOUT = 1500
const WAIT_FOR_WRITE_RETRY_AFTER = 500
const MAX_ERRORS_IN_ROW = 25

const helperCheckHasAck = (buffer) => {
  let foundFirstAck = false
  let acked = false
  for (const b of buffer) {
    if (!foundFirstAck) {
      if (b === 0xaa) {
        foundFirstAck = true
      }
    } else {
      if (b === 0xaa) {
        acked = true
        break
      } else {
        foundFirstAck = false
      }
    }
  }
  return acked
}

const legacySerialBootloaderFSM = async (
  port: React.MutableRefObject<AcriosSerial>,
  event: EVENT_TYPE,
  onFinishedCallback: Function,
  eventData?
) => {
  const now = new Date().valueOf()
  const previousState = refBootloaderCtx.state

  switch (refBootloaderCtx.state) {
    case BOOTLOADER_STATE.IDLE:
      if (event === EVENT_TYPE.PERIODIC) {
        if (refBootloaderCtx.errorsInRow < MAX_ERRORS_IN_ROW) {
          showNotification(
            'Bootloader',
            `Resetting the device and connecting to the bootloader`
          )
          console.log(
            `[BOOTLOADER] reset the device and start the boootloader process... (retry ${refBootloaderCtx.errorsInRow}/${MAX_ERRORS_IN_ROW})`
          )
          refBootloaderCtx.rxBuffer = []
          sendResetDevice(port)
          refBootloaderCtx.state = BOOTLOADER_STATE.BL_DOTS
          refBootloaderCtx.resetTimestamp = now
        } else {
          console.error('[BOOTLOADER] too many retries, abort...')
          refBootloaderCtx.state = BOOTLOADER_STATE.ERROR
          onFinishedCallback()
          showNotification(
            'Bootloader',
            `Too many retries! Aborting...`,
            undefined,
            NotificationLevel.ERROR
          )
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        // ignore
      } else {
        // unknown event
      }

      break

    // wait for timeout and go to idle or get pong to periodic pings + send erase first page command
    case BOOTLOADER_STATE.BL_DOTS:
      if (event === EVENT_TYPE.PERIODIC) {
        if (now - refBootloaderCtx.resetTimestamp > WAIT_FOR_DOTS_TIMEOUT) {
          refBootloaderCtx.errorsInRow += 1
          refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
          return
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        refBootloaderCtx.rxBuffer.push(...eventData)
      } else {
        // unknown event
      }

      let foundDots = 0
      for (const b of refBootloaderCtx.rxBuffer) {
        if (b === '.'.charCodeAt(0)) {
          foundDots += 1
        }
      }
      if (foundDots > 5) {
        console.log(`[BOOTLOADER] erase first page`)
        refBootloaderCtx.eraseTimestamp = now
        refBootloaderCtx.retryTimestamp = now
        refBootloaderCtx.rxBuffer = []
        sendErasePage(port, 0)
        refBootloaderCtx.state = BOOTLOADER_STATE.WFA_ERASE_FIRST
      }

      break

    // wait for timeout, increment retry and go to idle or get acknowledge and move forward, erasing 2nd page
    case BOOTLOADER_STATE.WFA_ERASE_FIRST:
      if (event === EVENT_TYPE.PERIODIC) {
        if (now - refBootloaderCtx.eraseTimestamp > WAIT_FOR_ERASE_TIMEOUT) {
          refBootloaderCtx.errorsInRow += 1
          refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
          return
        } else {
          if (
            now - refBootloaderCtx.retryTimestamp >
            WAIT_FOR_ERASE_RETRY_AFTER
          ) {
            console.log(`[BOOTLOADER] resend erase first page`)
            refBootloaderCtx.retryTimestamp = now
            sendErasePage(port, 0)
          }
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        refBootloaderCtx.rxBuffer.push(...eventData)
      } else {
        // unknown event
      }

      if (helperCheckHasAck(refBootloaderCtx.rxBuffer)) {
        console.log(`[BOOTLOADER] erase page number 1`)
        refBootloaderCtx.eraseTimestamp = now
        refBootloaderCtx.retryTimestamp = now
        refBootloaderCtx.rxBuffer = []
        refBootloaderCtx.currentPage = 1
        sendErasePage(port, refBootloaderCtx.currentPage)
        refBootloaderCtx.state = BOOTLOADER_STATE.WFA_ERASE
      }
      break

    case BOOTLOADER_STATE.WFA_ERASE:
      if (event === EVENT_TYPE.PERIODIC) {
        if (now - refBootloaderCtx.eraseTimestamp > WAIT_FOR_ERASE_TIMEOUT) {
          refBootloaderCtx.errorsInRow += 1
          refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
          return
        } else {
          if (
            now - refBootloaderCtx.retryTimestamp >
            WAIT_FOR_ERASE_RETRY_AFTER
          ) {
            console.log(
              `[BOOTLOADER] resend erase page number ${refBootloaderCtx.currentPage}`
            )
            refBootloaderCtx.retryTimestamp = now
            sendErasePage(port, refBootloaderCtx.currentPage)
          }
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        refBootloaderCtx.rxBuffer.push(...eventData)
      } else {
        // unknown event
      }

      if (helperCheckHasAck(refBootloaderCtx.rxBuffer)) {
        console.log(
          `[BOOTLOADER] write page number ${refBootloaderCtx.currentPage}`
        )
        refBootloaderCtx.writeTimestamp = now
        refBootloaderCtx.retryTimestamp = now
        refBootloaderCtx.rxBuffer = []
        const res = sendProgramPage(
          port,
          refBootloaderCtx.currentPage,
          refBootloaderCtx.fw
        )
        if (res === true) {
          refBootloaderCtx.state = BOOTLOADER_STATE.WFA_WRITE
        }
      }
      break

    case BOOTLOADER_STATE.WFA_WRITE:
      if (event === EVENT_TYPE.PERIODIC) {
        if (now - refBootloaderCtx.writeTimestamp > WAIT_FOR_WRITE_TIMEOUT) {
          refBootloaderCtx.errorsInRow += 1
          refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
          return
        } else {
          if (
            now - refBootloaderCtx.retryTimestamp >
            WAIT_FOR_WRITE_RETRY_AFTER
          ) {
            console.log(
              `[BOOTLOADER] resend write page number ${refBootloaderCtx.currentPage}`
            )
            refBootloaderCtx.retryTimestamp = now
            sendProgramPage(
              port,
              refBootloaderCtx.currentPage,
              refBootloaderCtx.fw
            )
          }
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        refBootloaderCtx.rxBuffer.push(...eventData)
      } else {
        // unknown event
      }

      if (helperCheckHasAck(refBootloaderCtx.rxBuffer)) {
        refBootloaderCtx.retryTimestamp = now
        refBootloaderCtx.rxBuffer = []
        refBootloaderCtx.currentPage += 1

        showNotification(
          'Bootloader',
          `${isWhatPercentOf(
            refBootloaderCtx.currentPage,
            refBootloaderCtx.totalPages
          )}% - firmware update in progress... Keep the page open and do not disconnect the device!`,
          'desktop-download'
        )

        if (refBootloaderCtx.currentPage >= refBootloaderCtx.totalPages) {
          console.log('[BOOTLOADER] write first page')
          refBootloaderCtx.writeTimestamp = now
          sendProgramPage(port, 0, refBootloaderCtx.fw)
          refBootloaderCtx.state = BOOTLOADER_STATE.WFA_WRITE_FIRST
        } else {
          console.log(
            `[BOOTLOADER] erase page number ${refBootloaderCtx.currentPage}`
          )
          refBootloaderCtx.eraseTimestamp = now
          sendErasePage(port, refBootloaderCtx.currentPage)
          refBootloaderCtx.state = BOOTLOADER_STATE.WFA_ERASE
        }
      }
      break

    case BOOTLOADER_STATE.WFA_WRITE_FIRST:
      if (event === EVENT_TYPE.PERIODIC) {
        if (now - refBootloaderCtx.writeTimestamp > WAIT_FOR_WRITE_TIMEOUT) {
          refBootloaderCtx.errorsInRow += 1
          refBootloaderCtx.state = BOOTLOADER_STATE.IDLE
          return
        } else {
          if (
            now - refBootloaderCtx.retryTimestamp >
            WAIT_FOR_WRITE_RETRY_AFTER
          ) {
            console.log(`[BOOTLOADER] resend write first page`)
            refBootloaderCtx.retryTimestamp = now
            sendProgramPage(port, 0, refBootloaderCtx.fw)
          }
        }
      } else if (event === EVENT_TYPE.ON_RECEIVED) {
        refBootloaderCtx.rxBuffer.push(...eventData)
      } else {
        // unknown event
      }

      if (helperCheckHasAck(refBootloaderCtx.rxBuffer)) {
        refBootloaderCtx.eraseTimestamp = now
        refBootloaderCtx.retryTimestamp = now
        refBootloaderCtx.rxBuffer = []
        sendBootDevice(port)
        refBootloaderCtx.state = BOOTLOADER_STATE.DONE
        onFinishedCallback()
        showNotification(
          'Bootloader',
          `Firmware update completed!`,
          'pass-filled'
        )
        console.log('[BOOTLOADER] done sending!')
      }
      break

    case BOOTLOADER_STATE.DONE:
      if (event === EVENT_TYPE.ON_RECEIVED) {
        console.log(
          `[BOOTLOADER] done, received ${bufferToHex(eventData)}, ignore`
        )
      }
      break

    case BOOTLOADER_STATE.ERROR:
      if (event === EVENT_TYPE.ON_RECEIVED) {
        console.log(
          `[BOOTLOADER] in error, received ${bufferToHex(eventData)}, ignore`
        )
      }
      break
  }

  if (previousState !== refBootloaderCtx.state) {
    console.warn(
      `[LEGACY BOOTLOADER] State changed ${previousState} => ${refBootloaderCtx.state}`
    )
  }
}

export {
  EVENT_TYPE,
  bootloaderStart,
  bootloaderGetState,
  legacySerialBootloaderFSM,
}
