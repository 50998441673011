export type Frame = {
  payload: Uint8Array
  id: number
  offset: number
  name: string
  txDelay: number
  binaryExpectedLength?: number
  replies: REPLY_TYPE[]
}

export enum REPLY_TYPE {
  // ASCII
  ACK = 'ACK',
  ERR = 'ERR',
  PONG = 'PONG',

  // binary
  RAW_DATA = 'rawData',
}

const FrameTypes = {
  WRITE_TO_SCRATCHPAD: {
    id: 14,
    offset: 1260,
    name: 'Write to scratchpad',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK, REPLY_TYPE.ERR],
  },
  READ_FROM_SCRATCHPAD: {
    id: 15,
    offset: 1260,
    name: 'Read from scratchpad',
    txDelay: 40,
    binaryExpectedLength: 34, //CHUNK_SIZE + 2,
    replies: [REPLY_TYPE.RAW_DATA],
  },
  MOVE_LUA_SCRIPT_TO_FLASH: {
    id: 16,
    offset: 1260,
    name: 'Move Lua script from scratchpad to flash',
    txDelay: 50,
    replies: [REPLY_TYPE.ACK, REPLY_TYPE.ERR],
  },
  MOVE_LUA_SCRIPT_TO_SCRATCHPAD: {
    id: 17,
    offset: 1260,
    name: 'Move Lua script from flash to scratchpad',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK, REPLY_TYPE.ERR],
  },
  START_LUA_INTERACTIVE_MODE: {
    id: 18,
    offset: 1260,
    name: 'Start Lua interactive mode',
    txDelay: 40,
    replies: [],
  },
  NOOP: {
    id: 24,
    offset: 1264,
    name: 'No operation',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK],
  },
  SHOW_FW_VERSION: {
    id: 36,
    offset: 1268,
    name: 'Show device firmware version',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK],
  },
  LUA_EXECUTE_HACK: {
    id: 37,
    offset: 1268,
    name: 'Execute Lua chunk code',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK],
  },
  SHOW_MODEL_STRING: {
    id: 38,
    offset: 1268,
    name: 'Show device model name',
    txDelay: 40,
    replies: [REPLY_TYPE.ACK],
  },
  RESET_BOARD: {
    id: 254,
    offset: 1264,
    name: 'Reset the board',
    txDelay: 40,
    replies: [],
  },
  PING: {
    id: 255,
    offset: 1264,
    name: 'Ping',
    txDelay: 40,
    replies: [REPLY_TYPE.PONG],
  },
}

export default FrameTypes
