import { useEffect } from 'react'
import '@dtinsight/molecule/esm/style/mo.css'
import { Terminal } from 'xterm'
import LocalEchoController from 'local-echo'
import AcriosWorkbench from './views/AcriosWorkbench'
import './App.css'
import SerialProvider from './api/serialProvider'
import { useLocalStorage } from 'usehooks-ts'
import { useGlobalState } from './state/state'
import { useModal } from './components/ModalProvider'
import TutorialModal from './components/Tutorial'
import molecule from '@dtinsight/molecule'
import { GuiAction } from './extensions/types'
;(window as any).__DEVELOPMENT__ = false

// snow
// import Snowfall from 'react-snowfall'
// const snowflake1 = document.createElement('img')
// snowflake1.src = '/show-flake-192x192.png'
// const snowflake2 = document.createElement('img')
// snowflake2.src = '/android-chrome-192x192.png'
// snow

const App = () => {
  const [_localEchoTerm, setLocalEchoTerm] = useGlobalState('localEchoTerm')
  const [isFirstRun, setFirstRun] = useLocalStorage('firstRun', true)

  const modal = useModal()

  useEffect(() => {
    if (navigator.onLine) {
      fetch('/luac.wasm') // prefetch compiler for offline installation
      fetch('/xz.wasm') // prefetch xz for offline installation
    }

    //Init the xterm globally so we have a reference right from the app startup
    const term = new Terminal({
      cursorBlink: true,
      lineHeight: 1.3,
      fontSize: 14,
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      theme: { background: '#1e1e1e' },
    })

    // local echo for terminal behaviour
    const localEchoTerm = new LocalEchoController(null, {
      historySize: 300,
    })
    term.loadAddon(localEchoTerm)

    setLocalEchoTerm(localEchoTerm)

    molecule.event.EventBus.subscribe(GuiAction.SHOW_TUTORIAL, () => {
      modal.setVisible(true)
      modal.setContent(<TutorialModal close={() => modal.setVisible(false)} />)
    })

    // TODO: finish welcome modal

    // if (isFirstRun) {
    //   modal.setVisible(true)
    //   modal.setContent(<TutorialModal close={() => modal.setVisible(false)} />)
    //   setFirstRun(false)
    // }

    // autocomplete
    // localEchoTerm.addAutocompleteHandler((index) => {
    //   if (index !== 0) return []

    //   // TODO: fill with lua api
    //   return ['api', 'print']
    // })

    // ;(window as any).localEchoTerm = localEchoTerm
    ;(window as any).term = term
  }, [])

  return navigator?.vendor && /Google/.test(navigator?.vendor) ? (
    <div className="App">
      <SerialProvider>
        <AcriosWorkbench />
      </SerialProvider>
    </div>
  ) : (
    <p style={{ textAlign: 'center' }}>
      To run this app you need to use{' '}
      <a href="https://www.google.com/chrome/">Google Chrome</a>
    </p>
  )
}

export default App
