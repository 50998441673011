import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { create } from '@dtinsight/molecule'
import extensions from './extensions/'
import { register as registerServiceWorker } from './serviceWorkerRegistration'
import ModalProvider from './components/ModalProvider'
import 'bootstrap/dist/css/bootstrap.min.css'

const moInstance = create({
  extensions: extensions,
})

const MoleculeWrapper = () => moInstance.render(<App />)

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <MoleculeWrapper />
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
registerServiceWorker({
  onUpdate: (registration) => {
    console.log('PWA - onUpdate()')
    console.dir(registration)
  },
  onSuccess: (registration) => {
    console.log('PWA - onSuccess()')
    console.dir(registration)
  },
})
