/**
 * Welcome component
 */
const Welcome = () => {
  return (
    <div className="mo-editor">
      <div className="mo-welcome">
        <img
          style={{
            width: '300px',
            opacity: 0.5,
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          alt="Welcome!"
          src="/welcomeLogo.png"
        />
      </div>
    </div>
  )
}

export default Welcome
