import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FW_VERSION } from 'src/api/fwVersion'
import { getLatestAPIVersion } from 'src/api/luaVersions'
import { DEVICE_STATE } from 'src/api/serialProvider'

type DeviceInfoModalProps = {
  deviceState: {
    type: string
    fwVersion: FW_VERSION
    state: DEVICE_STATE
  }
  show: boolean
  onClose: () => void
}

const DeviceInfoModal: React.FC<DeviceInfoModalProps> = ({
  deviceState,
  onClose,
  show,
}) => {
  const latestVersion = getLatestAPIVersion()

  return (
    <Modal
      show={show}
      data-bs-theme="dark"
      className="acrios-modal"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Device Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Device: <span className="text-muted">{deviceState.type}</span>
        </p>
        <p>
          State: <span className="text-muted">{deviceState.state}</span>
        </p>
        <p className="mb-0">
          Firmware Version:{' '}
          <span className="text-muted">
            {deviceState.fwVersion.major}.{deviceState.fwVersion.minor}.
            {deviceState.fwVersion.patch}
          </span>
          {latestVersion &&
            (deviceState.fwVersion.major < latestVersion.major ||
            (deviceState.fwVersion.major === latestVersion.major &&
              deviceState.fwVersion.minor < latestVersion.minor) ||
            (deviceState.fwVersion.major === latestVersion.major &&
              deviceState.fwVersion.minor === latestVersion.minor &&
              deviceState.fwVersion.patch < latestVersion.patch) ? (
              <span className="text-warning"> (newer version available)</span>
            ) : (
              <span className="text-success"> (latest)</span>
            ))}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeviceInfoModal
