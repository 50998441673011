import molecule from '@dtinsight/molecule'
import { IExtension } from '@dtinsight/molecule/esm/model/extension'
import { IExtensionService } from '@dtinsight/molecule/esm/services'
import {
  IFolderTreeNodeProps,
  FileTypes,
  FolderTreeEvent,
} from '@dtinsight/molecule/esm/model'
import { transformToEditorTab, updateStatusBarLanguage } from '../../common'
import { UniqueId } from '@dtinsight/molecule/esm/common/types'
import { IMenuItemProps } from '@dtinsight/molecule/esm/components'

const ACRIOS_SAMPLES_URL =
  'https://sw.acrios.com/api/v4/projects/102/repository'

const LUA_ROOT = {
  id: 0,
  name: 'Lua files',
  location: 'lua-scripts',
}
const LUA_SAMPLES = {
  id: 1,
  name: 'Lua Sample Repository',
  location: 'samples',
}
const LUA_LOCAL = {
  id: 3,
  name: 'Local files',
  location: 'local',
  database: 'files',
}
const LUA_READOUT = {
  id: 2,
  name: 'Device Readouts History',
  location: 'readouts',
  database: 'readout_history',
}

export class AcrSamplesExtension implements IExtension {
  id: string = ''
  name: string = ''

  constructor(
    id: string = 'acrSamples',
    name: string = 'ACRIOS Lua Samples Git Repo Parser'
  ) {
    this.id = id
    this.name = name
  }

  activate(extensionCtx: IExtensionService): void {
    // root folder
    molecule.folderTree.add({
      ...LUA_ROOT,
      isLeaf: false,
      fileType: FileTypes.RootFolder,
    })

    // local files folder
    molecule.folderTree.add(
      {
        ...LUA_LOCAL,
        isLeaf: false,
        fileType: FileTypes.Folder,
        children: [],
      },
      LUA_ROOT.id
    )

    // readout files folder
    molecule.folderTree.add(
      {
        ...LUA_READOUT,
        isLeaf: false,
        fileType: FileTypes.Folder,
        children: [],
      },
      LUA_ROOT.id
    )

    // fetch all locally saved files
    // TODO: wait for download
    this.loadLuaLocalFiles()

    // fetch all samples at startup
    this.fetchLuaSamples()

    molecule.folderTree.onContextMenu(
      (contextmenu: IMenuItemProps, treenode: IFolderTreeNodeProps) => {
        console.log('HELLO')
      }
    )
    // event handlers
    molecule.folderTree.onRemove(async (id: UniqueId) => {
      //Remove the file from the folderTree and update the database accordingly
      molecule.folderTree.remove(id)
      molecule.event.EventBus.emit('activeTab')
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    molecule.folderTree.onUpdateFileName(async () => {
      //Propagate changes to LS
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    molecule.folderTree.onDropTree(async (source, target) => {
      try {
        //Removes the source file and recreates the copy of deleted file in the target
        const sourceCopy = source
        sourceCopy.id = Date.now() + Math.floor(Math.random() * 1000)
        if (target.fileType === FileTypes.Folder) {
          molecule.folderTree.remove(source.id)
          let childParentNode = molecule.folderTree.get(target.id)?.children
          childParentNode.push(sourceCopy)
          molecule.folderTree.update({
            id: target.id,
            children: childParentNode,
          })
          this.updateDatabaseFiles(LUA_LOCAL)
          this.updateDatabaseFiles(LUA_READOUT)
        }
      } catch (error) {
        console.log('Failed while moving the file/folder')
      }
    })

    molecule.folderTree.onSelectFile(async (file: IFolderTreeNodeProps) => {
      // molecule.event.EventBus.emit("onSelectFile 1", file)
      console.log('onSelectFile', file)

      if (!file.localFile) {
        // download and show selected Lua sample (From Git)
        // NOTE: for some reason location contains name instead of url, sometimes
        if (
          file.location.includes(LUA_SAMPLES.location) ||
          file.location.includes(LUA_SAMPLES.name)
        ) {
          if (navigator.onLine) {
            await fetch(`${ACRIOS_SAMPLES_URL}/files/${file.name}?ref=master`)
              .then((response) => response.json())
              .then((data) => {
                file.data = {
                  language: 'lua',
                  value: atob(data.content),
                }
                molecule.editor.open(transformToEditorTab(file))
                molecule.event.EventBus.emit('activeTab')
                updateStatusBarLanguage(file.data.language)
              })
              .catch(function (err) {
                console.error(
                  '[GITLAB] Unable to fetch data from sample repository:',
                  err
                )
              })
          }
        }
      } else {
        console.log('[LS] Fetch file from localstorage')
        molecule.editor.open(transformToEditorTab(file))
        molecule.event.EventBus.emit('activeTab')
        updateStatusBarLanguage(file.data.language)
      }
    })

    molecule.editor.onSelectTab((tabId: UniqueId, groupId: UniqueId) => {
      if (groupId) {
        const group = molecule.editor.getGroupById(groupId)
        if (group) {
          const tab: any = molecule.editor.getTabById(tabId, group.id!)
          if (tab) {
            updateStatusBarLanguage(tab.data!.language!)
          }
        }
      }
    })

    molecule.editor.onCloseAll(() => {
      molecule.event.EventBus.emit('activeTab')
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    molecule.editor.onCloseTab(() => {
      molecule.event.EventBus.emit('activeTab')
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    molecule.editor.onSelectTab((asd) => {
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    //Monitor changes in the tab.
    molecule.editor.onUpdateTab((tab) => {
      //This is a global flag that signifies if the current session is different from session persisted in the database.
      ;(window as any).isSessionDirty = true
      if (!document.title.includes('❗')) document.title = '❗' + document.title
    })

    molecule.folderTree.onCreate((type, nodeId) => {
      //Expand the folder tree when needed
      let expandKeys = molecule.folderTree.getExpandKeys() || []
      expandKeys.push(0)
      expandKeys.push(nodeId)
      molecule.folderTree.setExpandKeys(expandKeys)

      try {
        //Always fall back to "Local Files" top folder when outside that group
        if (nodeId < LUA_LOCAL.id) {
          nodeId = LUA_LOCAL.id
        }
        let childParentNode = molecule.folderTree.get(nodeId)?.children
        if (!childParentNode) {
          console.error(`Could not get lua local folder!`)
          return
        }

        const generatedFileID = Date.now()
        if (type === FileTypes.File)
          //Creates a new empty file
          childParentNode.push({
            id: generatedFileID,
            name: ' .lua',
            fileType: FileTypes.File,
            isLeaf: true,
            isEditable: false,
            icon: 'file-code',
            localFile: true,
            data: {
              language: 'lua',
              value: '',
            },
          })
        //Create a new empty folder
        else
          childParentNode.push({
            id: generatedFileID,
            name: 'New Folder',
            fileType: FileTypes.Folder,
            isLeaf: false,
            isEditable: false,
            icon: 'folder',
            children: [],
          })
        molecule.folderTree.update({
          id: nodeId,
          children: childParentNode,
        })
        //Molecule listent internally for FolderTreeEvent.onRename (src/controller/explorer/folderTree.tsx:138)
        molecule.event.EventBus.emit(FolderTreeEvent.onRename, generatedFileID)
        molecule.event.EventBus.emit('activeTab')
        this.updateDatabaseFiles(LUA_LOCAL)
      } catch (error) {
        console.error(
          '[FILE TREE] Exception occured while creating a new file or folder',
          error
        )
      }
    })

    //Creates a new file in the local folder
    molecule.event.EventBus.subscribe('newFile', (fileName: string, data: string) => {
      console.log('Creating a new file')
      if (!fileName) return
      let childParentNode = molecule.folderTree.get(LUA_LOCAL.id).children
      let newFile ={
        id: Date.now(),
        name: fileName,
        fileType: FileTypes.File,
        isLeaf: true,
        isEditable: false,
        icon: 'file-code',
        localFile: true,
        data: {
          language: 'lua',
          value: data,
        },
      }
      // Append the new file to local folder
      childParentNode.push(newFile)
      molecule.folderTree.update({
        id: LUA_LOCAL.id,
        children: childParentNode,
      })
      //Open a new tab with newly saved file
      molecule.editor.open(transformToEditorTab(newFile))
      molecule.event.EventBus.emit('activeTab')
      this.updateDatabaseFiles(LUA_LOCAL)
    })

    //Add new LUA readout file to the LUA readout archive
    molecule.event.EventBus.subscribe(
      'ImportLuaReadout',
      (readoutData, name) => {
        let childParentNode = molecule.folderTree.get(LUA_READOUT.id)?.children
        let dateNow = Date.now()
        //Create a new file representation of lua readout
        let fileReadout = {
          id: Math.floor(dateNow * 1000) + 1,
          name: new Date(dateNow).toISOString() + '.lua',
          fileType: FileTypes.File,
          isLeaf: true,
          isEditable: false,
          icon: 'file-code',
          localFile: true,
          data: {
            language: 'lua',
            value: readoutData,
          },
        }
        // Append the readout to readouts folder
        childParentNode.push(fileReadout)
        molecule.folderTree.update({
          id: LUA_READOUT.id,
          children: childParentNode,
        })
        //Open a new tab with newly saved file
        molecule.editor.open(transformToEditorTab(fileReadout))
        molecule.event.EventBus.emit('activeTab')
        this.updateDatabaseFiles(LUA_READOUT)
      }
    )

    //Local files importer, can be called via molecule event bus
    molecule.event.EventBus.subscribe('ImportLocalFiles', async () => {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        let virtualFileInput = document.createElement('input')
        virtualFileInput.type = 'file'
        virtualFileInput.accept = '.lua'
        virtualFileInput.multiple = true
        virtualFileInput.onchange = async () => {
          try {
            let files = Array.from(virtualFileInput.files)
            let childParentNode = molecule.folderTree.get(LUA_LOCAL.id).children
            for (let index = 0; index < files.length; index++) {
              const reader = new FileReader()
              reader.addEventListener('load', (event) => {
                const generatedFileID = Date.now() + files[index].lastModified
                const importedFile = {
                  id: generatedFileID,
                  name: files[index].name,
                  fileType: FileTypes.File,
                  isLeaf: true,
                  isEditable: false,
                  icon: 'file-code',
                  localFile: true,
                  data: {
                    language: 'lua',
                    value: event.target.result,
                  },
                }
                childParentNode.push(importedFile)
                //Update the folderTree in each loop, this makes a nice feedback while importing large number of files.
                molecule.folderTree.update({
                  id: LUA_LOCAL.id,
                  children: childParentNode,
                })
                //Propagate the file in the indexedDB
                molecule.editor.open(transformToEditorTab(importedFile))
                molecule.event.EventBus.emit('activeTab')
                this.updateDatabaseFiles(LUA_LOCAL, false, generatedFileID)
              })
              reader.readAsText(files[index])
            }
          } catch (error) {
            console.error(
              '[LS] Error while importing all / some of the files.',
              error
            )
          } finally {
            //File import is over
          }
        }
        virtualFileInput.click()
      } else {
        console.error(
          '[LS] The File APIs are not fully supported in your browser.'
        )
      }
    })

    //Forces save into the IndexedDB
    molecule.event.EventBus.subscribe('forceSave', () => {
      this.updateDatabaseFiles(LUA_LOCAL)
    })
  }

  dispose(extensionCtx: IExtensionService): void {
    throw new Error('Method not implemented.')
  }

  /**
   * Fetches Lua samples from GitLab and populates folder tree with results
   */
  async fetchLuaSamples() {
    let samples = []

    const total = 100
    let page = 1
    let totalPages = 1

    const fetchAllScripts = async () => {
      if (navigator.onLine) {
        await fetch(`${ACRIOS_SAMPLES_URL}/tree?per_page=${total}&page=${page}`)
          .then((response) => {
            // parse number of total pages
            totalPages = parseInt(
              response.headers.get('x-total-pages') ?? '1',
              10
            )
            return response.json()
          })
          .then((data) => {
            data.forEach((element) => {
              if (element.name.includes('.lua')) {
                if (navigator.onLine) {
                  fetch(
                    `${ACRIOS_SAMPLES_URL}/files/${element.name}?ref=master`
                  ) // prefetch script for offline installation
                }
                samples.push({
                  id: element.id,
                  name: element.name,
                  fileType: FileTypes.File,
                  isLeaf: true,
                  isEditable: false,
                  icon: 'file-code',
                  localFile: false,
                })
              }
            })
          })
          .catch(function (err) {
            console.error(
              '[GITLAB] Unable to fetch file listing from sample repository:',
              err
            )
          })
      }

      // move to next page
      if (page < totalPages) {
        page += 1
        await fetchAllScripts()
      }
    }

    await fetchAllScripts()

    const luaSamplesFolderTree = molecule.folderTree.get(LUA_SAMPLES.id)
    if (!luaSamplesFolderTree) {
      molecule.folderTree.add(
        {
          ...LUA_SAMPLES,
          isLeaf: false,
          fileType: FileTypes.Folder,
          children: samples,
        },
        LUA_ROOT.id
      )
    } else {
      molecule.folderTree.update({
        id: LUA_SAMPLES.id,
        children: samples,
      })
    }
  }

  /**
   * Returns IndexedDB connector with autoamtic fallback to IndexedDBShim library if the browser's IndexeDB is not availible.
   */
  IndexedDbConnection() {
    // This works on all devices/browsers, and uses IndexedDBShim as a final fallback (if needed)
    // ref.: https://www.npmjs.com/package/indexeddbshim
    const indexedDB =
      window.indexedDB ||
      (window as any).mozIndexedDB ||
      (window as any).webkitIndexedDB ||
      (window as any).msIndexedDB ||
      (window as any).shimIndexedDB
    const openDB = indexedDB.open('acrGui', 1)
    //Ensures the database structure (Files and LUA readout history)
    openDB.onupgradeneeded = () => {
      if (!openDB.result.objectStoreNames.contains('files')) {
        openDB.result.createObjectStore('files', { keyPath: 'id' })
      }
      if (!openDB.result.objectStoreNames.contains('readout_history')) {
        openDB.result.createObjectStore('readout_history', { keyPath: 'id' })
      }
    }
    return openDB
  }

  /**
   * Saves Lua files from local storage of a browser (As base64 encoded JSON string of molecule file/folder nodes)
   * If all is true then all files are updated, if file is false then only the supplied id is updated.
   * Adding is hanled using put (upsert) - New files are added, ecisting are automatically updated
   */
  updateDatabaseFiles(structure, all = true, id = null) {
    //TODO: To be improved further
    if ((window as any).isSessionDirty) {
      try {
        let localFiles = molecule.folderTree.get(structure.id).children
        let dbRequest = this.IndexedDbConnection()
        dbRequest.onsuccess = () => {
          let transaction = dbRequest.result.transaction(
            structure.database,
            'readwrite'
          )
          let dbFiles = transaction.objectStore(structure.database)
          if (all) {
            dbFiles.clear()
            localFiles.forEach((element) => {
              dbFiles.put(element)
            })
          } else {
            dbFiles.put(localFiles.find((element) => element.id === id))
          }
          ;(window as any).isSessionDirty = false
          document.title = document.title.replace('❗', '')
        }
      } catch (error) {
        console.error(
          "[LS] Error while saving files to browser's IndexedDB",
          error
        )
      }
    }
  }

  /**
   * Loads locally saved Lua files from local storage of a browser
   * If the "acrGui" is not availbile, it will automatically be created in IndexedDB on app startup.
   */
  loadLuaLocalFiles() {
    try {
      //TODO: To be improved - should only update the particular file instead of whole database
      let dbRequest = this.IndexedDbConnection()
      dbRequest.onsuccess = () => {
        //Fetch local LUA files
        let fetchLocalFiles = dbRequest.result
          .transaction('files', 'readwrite')
          .objectStore('files')
          .getAll()

        fetchLocalFiles.onsuccess = () => {
          molecule.folderTree.update({
            id: LUA_LOCAL.id,
            children: fetchLocalFiles.result,
          })
        }

        //Fetch LUA readout archive
        let fetchReadoutHistory = dbRequest.result
          .transaction('readout_history', 'readwrite')
          .objectStore('readout_history')
          .getAll()

        fetchReadoutHistory.onsuccess = () => {
          molecule.folderTree.update({
            id: LUA_READOUT.id,
            children: fetchReadoutHistory.result,
          })
        }
      }
    } catch (error) {
      console.error(
        "[LS] Error while loading files from browser's IndexedDB (Loading skipped)",
        error
      )
    }
  }
}
