
function id(x) { return x[0]; }

const tokens = require("./tokens")
const { makeLexer } = require("moo-ignore")

let lexer = makeLexer(tokens)
lexer.ignore("comment", "ws", "nl")

const INT_MAX = 16777216 // 2^24
const INT_MIN = -16777216

var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "chunk$ebnf$1", "symbols": []},
    {"name": "chunk$ebnf$1$subexpression$1$ebnf$1", "symbols": [(lexer.has("semicolon") ? {type: "semicolon"} : semicolon)], "postprocess": id},
    {"name": "chunk$ebnf$1$subexpression$1$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "chunk$ebnf$1$subexpression$1", "symbols": ["stat", "chunk$ebnf$1$subexpression$1$ebnf$1"]},
    {"name": "chunk$ebnf$1", "symbols": ["chunk$ebnf$1", "chunk$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "chunk$ebnf$2$subexpression$1$ebnf$1", "symbols": [(lexer.has("semicolon") ? {type: "semicolon"} : semicolon)], "postprocess": id},
    {"name": "chunk$ebnf$2$subexpression$1$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "chunk$ebnf$2$subexpression$1", "symbols": ["laststat", "chunk$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "chunk$ebnf$2", "symbols": ["chunk$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "chunk$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "chunk", "symbols": ["chunk$ebnf$1", "chunk$ebnf$2"], "postprocess":  ([stats, laststat]) => {
          const res = []
          if (stats) res.push(stats)
          if (laststat) res.push(laststat)
          return res.length === 1 ? res[0] : res 
        } },
    {"name": "block", "symbols": ["chunk"]},
    {"name": "stat", "symbols": ["varlist", (lexer.has("assign") ? {type: "assign"} : assign), "explist"]},
    {"name": "stat", "symbols": ["functioncall"]},
    {"name": "stat", "symbols": [(lexer.has("kw_do") ? {type: "kw_do"} : kw_do), "block", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)]},
    {"name": "stat", "symbols": [(lexer.has("kw_while") ? {type: "kw_while"} : kw_while), "exp", (lexer.has("kw_do") ? {type: "kw_do"} : kw_do), "block", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)]},
    {"name": "stat", "symbols": [(lexer.has("kw_repeat") ? {type: "kw_repeat"} : kw_repeat), "block", (lexer.has("kw_until") ? {type: "kw_until"} : kw_until), "exp"]},
    {"name": "stat$ebnf$1", "symbols": []},
    {"name": "stat$ebnf$1$subexpression$1", "symbols": [(lexer.has("kw_elseif") ? {type: "kw_elseif"} : kw_elseif), "exp", (lexer.has("kw_then") ? {type: "kw_then"} : kw_then), "block"]},
    {"name": "stat$ebnf$1", "symbols": ["stat$ebnf$1", "stat$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "stat$ebnf$2$subexpression$1", "symbols": [(lexer.has("kw_else") ? {type: "kw_else"} : kw_else), "block"]},
    {"name": "stat$ebnf$2", "symbols": ["stat$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "stat$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "stat", "symbols": [(lexer.has("kw_if") ? {type: "kw_if"} : kw_if), "exp", (lexer.has("kw_then") ? {type: "kw_then"} : kw_then), "block", "stat$ebnf$1", "stat$ebnf$2", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)]},
    {"name": "stat$ebnf$3$subexpression$1", "symbols": [(lexer.has("comma") ? {type: "comma"} : comma), "exp"]},
    {"name": "stat$ebnf$3", "symbols": ["stat$ebnf$3$subexpression$1"], "postprocess": id},
    {"name": "stat$ebnf$3", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "stat", "symbols": [(lexer.has("kw_for") ? {type: "kw_for"} : kw_for), (lexer.has("name") ? {type: "name"} : name), (lexer.has("assign") ? {type: "assign"} : assign), "exp", (lexer.has("comma") ? {type: "comma"} : comma), "exp", "stat$ebnf$3", (lexer.has("kw_do") ? {type: "kw_do"} : kw_do), "block", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)]},
    {"name": "stat", "symbols": [(lexer.has("kw_for") ? {type: "kw_for"} : kw_for), "namelist", (lexer.has("kw_in") ? {type: "kw_in"} : kw_in), "explist", (lexer.has("kw_do") ? {type: "kw_do"} : kw_do), "block", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)]},
    {"name": "stat", "symbols": [(lexer.has("kw_fn") ? {type: "kw_fn"} : kw_fn), "funcname", "funcbody"], "postprocess":  ([_, fnName, fnBody]) => {    
          return [fnName, fnBody]
        }  
          },
    {"name": "stat", "symbols": [(lexer.has("kw_local") ? {type: "kw_local"} : kw_local), (lexer.has("kw_fn") ? {type: "kw_fn"} : kw_fn), "funcname", "funcbody"]},
    {"name": "stat$ebnf$4$subexpression$1", "symbols": [(lexer.has("assign") ? {type: "assign"} : assign), "explist"]},
    {"name": "stat$ebnf$4", "symbols": ["stat$ebnf$4$subexpression$1"], "postprocess": id},
    {"name": "stat$ebnf$4", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "stat", "symbols": [(lexer.has("kw_local") ? {type: "kw_local"} : kw_local), "namelist", "stat$ebnf$4"]},
    {"name": "laststat$ebnf$1$subexpression$1", "symbols": ["explist"]},
    {"name": "laststat$ebnf$1", "symbols": ["laststat$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "laststat$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "laststat", "symbols": [(lexer.has("kw_return") ? {type: "kw_return"} : kw_return), "laststat$ebnf$1"]},
    {"name": "laststat", "symbols": [(lexer.has("kw_break") ? {type: "kw_break"} : kw_break)]},
    {"name": "funcname$ebnf$1", "symbols": []},
    {"name": "funcname$ebnf$1$subexpression$1", "symbols": [(lexer.has("dot") ? {type: "dot"} : dot), (lexer.has("name") ? {type: "name"} : name)]},
    {"name": "funcname$ebnf$1", "symbols": ["funcname$ebnf$1", "funcname$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "funcname$ebnf$2$subexpression$1", "symbols": [(lexer.has("colon") ? {type: "colon"} : colon), (lexer.has("name") ? {type: "name"} : name)]},
    {"name": "funcname$ebnf$2", "symbols": ["funcname$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "funcname$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "funcname", "symbols": [(lexer.has("name") ? {type: "name"} : name), "funcname$ebnf$1", "funcname$ebnf$2"]},
    {"name": "varlist$ebnf$1", "symbols": []},
    {"name": "varlist$ebnf$1$subexpression$1", "symbols": [(lexer.has("comma") ? {type: "comma"} : comma), "var"]},
    {"name": "varlist$ebnf$1", "symbols": ["varlist$ebnf$1", "varlist$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "varlist", "symbols": ["var", "varlist$ebnf$1"]},
    {"name": "var", "symbols": [(lexer.has("name") ? {type: "name"} : name)]},
    {"name": "var", "symbols": ["prefixexp", (lexer.has("lbrack") ? {type: "lbrack"} : lbrack), "exp", (lexer.has("rbrack") ? {type: "rbrack"} : rbrack)]},
    {"name": "var", "symbols": ["prefixexp", (lexer.has("dot") ? {type: "dot"} : dot), (lexer.has("name") ? {type: "name"} : name)]},
    {"name": "namelist$ebnf$1", "symbols": []},
    {"name": "namelist$ebnf$1$subexpression$1", "symbols": [(lexer.has("comma") ? {type: "comma"} : comma), (lexer.has("name") ? {type: "name"} : name)]},
    {"name": "namelist$ebnf$1", "symbols": ["namelist$ebnf$1", "namelist$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "namelist", "symbols": [(lexer.has("name") ? {type: "name"} : name), "namelist$ebnf$1"]},
    {"name": "explist$ebnf$1", "symbols": []},
    {"name": "explist$ebnf$1$subexpression$1", "symbols": ["exp", (lexer.has("comma") ? {type: "comma"} : comma)]},
    {"name": "explist$ebnf$1", "symbols": ["explist$ebnf$1", "explist$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "explist", "symbols": ["explist$ebnf$1", "exp"]},
    {"name": "exp", "symbols": ["or_exp"]},
    {"name": "or_exp$ebnf$1", "symbols": []},
    {"name": "or_exp$ebnf$1$subexpression$1", "symbols": [(lexer.has("kw_or") ? {type: "kw_or"} : kw_or), "and_exp"]},
    {"name": "or_exp$ebnf$1", "symbols": ["or_exp$ebnf$1", "or_exp$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "or_exp", "symbols": ["and_exp", "or_exp$ebnf$1"]},
    {"name": "and_exp$ebnf$1", "symbols": []},
    {"name": "and_exp$ebnf$1$subexpression$1", "symbols": [(lexer.has("kw_and") ? {type: "kw_and"} : kw_and), "comparison_exp"]},
    {"name": "and_exp$ebnf$1", "symbols": ["and_exp$ebnf$1", "and_exp$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "and_exp", "symbols": ["comparison_exp", "and_exp$ebnf$1"]},
    {"name": "comparison_exp$ebnf$1", "symbols": []},
    {"name": "comparison_exp$ebnf$1$subexpression$1", "symbols": ["comparison_op", "additive_exp"]},
    {"name": "comparison_exp$ebnf$1", "symbols": ["comparison_exp$ebnf$1", "comparison_exp$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "comparison_exp", "symbols": ["additive_exp", "comparison_exp$ebnf$1"]},
    {"name": "additive_exp$ebnf$1", "symbols": []},
    {"name": "additive_exp$ebnf$1$subexpression$1", "symbols": ["additive_op", "multiplicative_exp"]},
    {"name": "additive_exp$ebnf$1", "symbols": ["additive_exp$ebnf$1", "additive_exp$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "additive_exp", "symbols": ["multiplicative_exp", "additive_exp$ebnf$1"]},
    {"name": "multiplicative_exp$ebnf$1", "symbols": []},
    {"name": "multiplicative_exp$ebnf$1$subexpression$1", "symbols": ["multiplicative_op", "unary_exp"]},
    {"name": "multiplicative_exp$ebnf$1", "symbols": ["multiplicative_exp$ebnf$1", "multiplicative_exp$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "multiplicative_exp", "symbols": ["unary_exp", "multiplicative_exp$ebnf$1"]},
    {"name": "unary_exp", "symbols": ["unop_exp"]},
    {"name": "unary_exp", "symbols": ["primary_exp"]},
    {"name": "unop_exp", "symbols": ["unop", "unary_exp"]},
    {"name": "primary_exp", "symbols": [(lexer.has("kw_nil") ? {type: "kw_nil"} : kw_nil)], "postprocess": (data) => data[0]},
    {"name": "primary_exp", "symbols": [(lexer.has("kw_true") ? {type: "kw_true"} : kw_true)], "postprocess": (data) => data[0]},
    {"name": "primary_exp", "symbols": [(lexer.has("kw_false") ? {type: "kw_false"} : kw_false)], "postprocess": (data) => data[0]},
    {"name": "primary_exp", "symbols": [(lexer.has("number") ? {type: "number"} : number)], "postprocess":  ([data]) => {
          if (data) {
            const number = parseInt(data.value)
            if (number > INT_MAX || number < INT_MIN) {
              throw {
                error: `Too ${number < INT_MIN ? "small" : "big"} number: ${number} found!`,
                line: data.line,
                col: data.col
              }
            } else return data
          }
        }
        },
    {"name": "primary_exp", "symbols": [(lexer.has("numberSci") ? {type: "numberSci"} : numberSci)], "postprocess":  ([number]) => {
          if (number) {
            throw {
              error: `Can not use numbers in scientific format! Found: ${number}`,
              line: number.line,
              col: number.col
            }
          }
        } 
        },
    {"name": "primary_exp", "symbols": [(lexer.has("numberFp") ? {type: "numberFp"} : numberFp)], "postprocess":  ([number]) => {
          if (number) {
            throw {
              error: `Can not use numbers in floating point format! Found: ${number}`,
              line: number.line,
              col: number.col
            }
          }
        }  
        },
    {"name": "primary_exp", "symbols": [(lexer.has("string") ? {type: "string"} : string)], "postprocess": (data) => data[0]},
    {"name": "primary_exp", "symbols": [(lexer.has("vararg") ? {type: "vararg"} : vararg)], "postprocess": (data) => data[0]},
    {"name": "primary_exp", "symbols": ["function"]},
    {"name": "primary_exp", "symbols": ["prefixexp"]},
    {"name": "primary_exp", "symbols": ["tableconstructor"]},
    {"name": "prefixexp", "symbols": ["var"]},
    {"name": "prefixexp", "symbols": ["functioncall"]},
    {"name": "prefixexp", "symbols": [(lexer.has("lparen") ? {type: "lparen"} : lparen), "exp", (lexer.has("rparen") ? {type: "rparen"} : rparen)], "postprocess": (data) => data[1]},
    {"name": "functioncall", "symbols": ["prefixexp", "args"]},
    {"name": "functioncall", "symbols": ["prefixexp", (lexer.has("colon") ? {type: "colon"} : colon), (lexer.has("name") ? {type: "name"} : name), "args"]},
    {"name": "args$ebnf$1$subexpression$1", "symbols": ["explist"]},
    {"name": "args$ebnf$1", "symbols": ["args$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "args$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "args", "symbols": [(lexer.has("lparen") ? {type: "lparen"} : lparen), "args$ebnf$1", (lexer.has("rparen") ? {type: "rparen"} : rparen)], "postprocess": (data) => data[1]},
    {"name": "args", "symbols": ["tableconstructor"]},
    {"name": "function", "symbols": [(lexer.has("kw_fn") ? {type: "kw_fn"} : kw_fn), "funcbody"]},
    {"name": "funcbody$ebnf$1$subexpression$1", "symbols": ["parlist"]},
    {"name": "funcbody$ebnf$1", "symbols": ["funcbody$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "funcbody$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "funcbody", "symbols": [(lexer.has("lparen") ? {type: "lparen"} : lparen), "funcbody$ebnf$1", (lexer.has("rparen") ? {type: "rparen"} : rparen), "block", (lexer.has("kw_end") ? {type: "kw_end"} : kw_end)], "postprocess":  
        ([_l, parlist, _r, block, _e]) => {
          if (parlist) {
            const restParLength = parlist[0][0][1]?.length
            const max = 32
            if (restParLength >= max) throw {
              error: `Used more ${restParLength + 1}/${max} args then allowed!`,
              line: parlist[0][0][0].line,
              col: parlist[0][0][0].col
            }
          }
        
          return [{ fnParlist: parlist }, { fnBlock: block }]
        }
        },
    {"name": "parlist$ebnf$1$subexpression$1", "symbols": [(lexer.has("comma") ? {type: "comma"} : comma), (lexer.has("vararg") ? {type: "vararg"} : vararg)]},
    {"name": "parlist$ebnf$1", "symbols": ["parlist$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "parlist$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "parlist", "symbols": ["namelist", "parlist$ebnf$1"]},
    {"name": "parlist", "symbols": [(lexer.has("vararg") ? {type: "vararg"} : vararg)]},
    {"name": "tableconstructor$ebnf$1$subexpression$1", "symbols": ["fieldlist"]},
    {"name": "tableconstructor$ebnf$1", "symbols": ["tableconstructor$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "tableconstructor$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "tableconstructor", "symbols": [(lexer.has("lbrace") ? {type: "lbrace"} : lbrace), "tableconstructor$ebnf$1", (lexer.has("rbrace") ? {type: "rbrace"} : rbrace)]},
    {"name": "fieldlist$ebnf$1", "symbols": []},
    {"name": "fieldlist$ebnf$1$subexpression$1", "symbols": ["fieldsep", "field"]},
    {"name": "fieldlist$ebnf$1", "symbols": ["fieldlist$ebnf$1", "fieldlist$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "fieldlist$ebnf$2$subexpression$1", "symbols": ["fieldsep"]},
    {"name": "fieldlist$ebnf$2", "symbols": ["fieldlist$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "fieldlist$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "fieldlist", "symbols": ["field", "fieldlist$ebnf$1", "fieldlist$ebnf$2"]},
    {"name": "field", "symbols": [(lexer.has("lbrack") ? {type: "lbrack"} : lbrack), "exp", (lexer.has("rbrack") ? {type: "rbrack"} : rbrack), (lexer.has("assign") ? {type: "assign"} : assign), "exp"]},
    {"name": "field", "symbols": [(lexer.has("name") ? {type: "name"} : name), (lexer.has("assign") ? {type: "assign"} : assign), "exp"]},
    {"name": "field", "symbols": ["exp"]},
    {"name": "fieldsep", "symbols": [(lexer.has("comma") ? {type: "comma"} : comma)]},
    {"name": "fieldsep", "symbols": [(lexer.has("semicolon") ? {type: "semicolon"} : semicolon)]},
    {"name": "comparison_op", "symbols": [(lexer.has("lt") ? {type: "lt"} : lt)]},
    {"name": "comparison_op", "symbols": [(lexer.has("le") ? {type: "le"} : le)]},
    {"name": "comparison_op", "symbols": [(lexer.has("gt") ? {type: "gt"} : gt)]},
    {"name": "comparison_op", "symbols": [(lexer.has("ge") ? {type: "ge"} : ge)]},
    {"name": "comparison_op", "symbols": [(lexer.has("eq") ? {type: "eq"} : eq)]},
    {"name": "comparison_op", "symbols": [(lexer.has("neq") ? {type: "neq"} : neq)]},
    {"name": "additive_op", "symbols": [(lexer.has("plus") ? {type: "plus"} : plus)]},
    {"name": "additive_op", "symbols": [(lexer.has("minus") ? {type: "minus"} : minus)]},
    {"name": "additive_op", "symbols": [(lexer.has("concat") ? {type: "concat"} : concat)]},
    {"name": "multiplicative_op", "symbols": [(lexer.has("times") ? {type: "times"} : times)]},
    {"name": "multiplicative_op", "symbols": [(lexer.has("div") ? {type: "div"} : div)]},
    {"name": "multiplicative_op", "symbols": [(lexer.has("percent") ? {type: "percent"} : percent)]},
    {"name": "multiplicative_op", "symbols": [(lexer.has("caret") ? {type: "caret"} : caret)]},
    {"name": "unop", "symbols": [(lexer.has("minus") ? {type: "minus"} : minus)]},
    {"name": "unop", "symbols": [(lexer.has("kw_not") ? {type: "kw_not"} : kw_not)]},
    {"name": "unop", "symbols": [(lexer.has("hash") ? {type: "hash"} : hash)]}
]
  , ParserStart: "chunk"
}

export default grammar