const moo = require("moo")

const checkKw = (defs) => {
  const keywords = moo.keywords(defs)
  return (value) => keywords(value)
}

module.exports = {
  comment: /--\[\[[\s\S]*?]]--|--[\s\S]*?$/,
  ws:             /[ \t]+/,
  numberSci:  /(?:-?\d+(?:\.\d+)?)[eE][-+]?\d+/,
  numberFp:   /-?\d+\.\d+/,
  number:     /0x[0-9a-fA-F]+|0|[1-9][0-9]*/,
  nl:             { match: /\n/, lineBreaks: true },
  name: {
    match: /[a-zA-Z_][a-zA-Z_0-9]*/,
    type: checkKw({
      kw_fn:          'function',
      kw_true:        'true',
      kw_false:       'false',
      kw_nil:         'nil',
      kw_local:       'local',
      kw_do:          'do',
      kw_then:        'then',
      kw_end:         'end',
      kw_elseif:      'elseif',
      kw_else:        'else',
      kw_if:          'if',
      kw_for:         'for',
      kw_while:       'while',
      kw_until:       'until',
      kw_repeat:      'repeat',
      kw_in:          'in',
      kw_break:       'break',
      kw_return:      'return',

      kw_and:         'and',
      kw_or:          'or',
      kw_not:         'not',
  })
  },
  string:         /(?:(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'))/,
  lparen: "(",
  rparen: ")",
  lbrace: "{",
  rbrace: "}",
  lbrack: "[",
  rbrack: "]",
  semicolon: ";",
  comma: ",",
  colon: ":",
  vararg: "...",

  minus: "-",
  plus: "+",
  times: "*",
  div: "/",
  caret: "^",
  percent: "%",
  hash: '#',
  concat: '..',

  le: "<=",
  lt: "<",

  ge: ">=",
  gt: ">",
  eq: "==",
  neq: "~=",

  assign: "=",
  dot: ".",
}