import { createContext, useContext, useState } from "react"
import { useBoolean } from "usehooks-ts"

const ModalContext = createContext({
  toggle: () => {},
  content: <></>,
  setContent: null,
  setVisible: null,
})

export const useModal = () => {
  return useContext(ModalContext)
}

/**
 * Wrapper for modals
 */
const ModalProvider = (props) => {
  const { value, setValue, toggle } = useBoolean(false)
  const [content, setContent] = useState(<></>)

  const contextValue = {
    toggle,
    content,
    setContent,
    setVisible: setValue
  }

  return (
    <ModalContext.Provider value={contextValue}>
      { props.children }

      { value && (
        <div style={{  
          height: "100vh", 
          position: 'absolute', 
          top: 0, left: 0, 
          width: "100%", 
          zIndex: 1, 
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          }}
        >
          { content }
        </div>
      )}
    </ModalContext.Provider>
  )
}

export default ModalProvider