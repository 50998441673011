import { padWithZeros } from './formatUtils'

/**
 * Returns current time in format HH:MM:SS:MLS
 */
export const getCurrentTime = () => {
  const value = new Date()

  return padWithZeros(value.getHours(), 2)
    .concat(':')
    .concat(padWithZeros(value.getMinutes(), 2))
    .concat(':')
    .concat(padWithZeros(value.getSeconds(), 2))
    .concat(':')
    .concat(padWithZeros(value.getMilliseconds(), 3))
}
