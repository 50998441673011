export const MAGIC = [0x42, 0x88, 0x08, 0xBB]

const CryptoJS = require("crypto-js")

/**
 * AES encrypt
 */
export const aesEncrypt = async (input: Uint8Array, pass: string) => {
  const passSha = CryptoJS.SHA256(pass)
  const encoder = new TextEncoder()
  const passShaByteArray = encoder.encode(passSha)
  const key = passShaByteArray.slice(0, 32)
  const iv = key.slice(0, 16)
  const algorithm = { name: 'AES-CBC', iv }
  const cryptoKey = await crypto.subtle.importKey('raw', key, algorithm, false, ['encrypt'])
  const encryptedData = await crypto.subtle.encrypt(algorithm, cryptoKey, input)
  /* @ts-ignore */
  const encryptedUint8Array = new Uint8Array([...iv, ...new Uint8Array(encryptedData)])
  return encryptedUint8Array
}

/**
 * AES decrypt
 */
export const aesDecrypt = async (encryptedData: Uint8Array, pass: string) => {
  const passSha = CryptoJS.SHA256(pass)
  const encoder = new TextEncoder()
  const passShaByteArray = encoder.encode(passSha)
  const key = passShaByteArray.slice(0, 32)
  const iv = encryptedData.slice(0, 16)
  const encryptedContent = encryptedData.slice(16)
  const algorithm = { name: 'AES-CBC', iv }
  const cryptoKey = await crypto.subtle.importKey('raw', key, algorithm, false, ['decrypt'])
  const decryptedData = await crypto.subtle.decrypt(algorithm, cryptoKey, encryptedContent)
  return new Uint8Array(decryptedData)
}