import { FC, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Col, Row } from 'react-bootstrap'

type ScriptPasswordModalProps = {
  show: boolean
  passwordInvalid: boolean
  handleSubmit: (password: string) => void
}

const ScriptPasswordModal: FC<ScriptPasswordModalProps> = ({
  show,
  passwordInvalid,
  handleSubmit,
}) => {
  const [password, setPassword] = useState('')
  const passwordRef = useRef(null)
  const inputRef = useRef(null)

  // focus on password input when modal is shown
  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus() // Focuses on the input when the modal is shown
    }
  }, [show])

  const onSubmit = (event) => {
    event.preventDefault()
    handleSubmit(password)
    passwordRef.current = password
  }

  return (
    <Modal
      show={show}
      data-bs-theme="dark"
      className="acrios-modal"
      backdrop="static"
      onHide={() => {
        handleSubmit(null)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Password Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Col xs={7}>
            <Form.Control
              required
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className="mt-1"
              ref={inputRef}
            />
            {passwordInvalid && password == passwordRef.current && (
              <Form.Text className="text-danger">Invalid Password</Form.Text>
            )}
          </Col>
          <Button type="submit" disabled={!password} className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default ScriptPasswordModal
