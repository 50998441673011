import 'reflect-metadata'
import { container } from 'tsyringe'
import molecule from '@dtinsight/molecule'
import {
  IFolderTreeController,
  FolderTreeController,
} from '@dtinsight/molecule/esm/controller/explorer/folderTree'
import { KeybindingWeight } from '@dtinsight/molecule/esm/monaco/common'
import { constants } from '@dtinsight/molecule/esm/services/builtinService/const'
import { Action2 } from '@dtinsight/molecule/esm/monaco/action'
import { localize } from '@dtinsight/molecule/esm/i18n/localize'
import { KeyMod, KeyCode } from '@dtinsight/molecule/esm/monaco'
import { KeyChord } from 'monaco-editor/esm/vs/base/common/keyCodes'

export class QuickCreateFile extends Action2 {
  static readonly ID = constants.ACTION_QUICK_CREATE_FILE
  static readonly LABEL = localize('menu.newFile', 'New File')
  static readonly DESC = 'New File'
  private readonly folderTreeController: IFolderTreeController

  constructor() {
    super({
      id: QuickCreateFile.ID,
      title: {
        value: QuickCreateFile.LABEL,
        original: QuickCreateFile.DESC,
      },
      label: QuickCreateFile.LABEL,
      alias: QuickCreateFile.DESC,
      f1: true,
      keybinding: {
        when: undefined,
        weight: KeybindingWeight.WorkbenchContrib,
        // eslint-disable-next-line new-cap
        primary: KeyChord(KeyMod.CtrlCmd | KeyCode.KeyN),
      },
    })
    this.folderTreeController = container.resolve(FolderTreeController)
  }

  run() {
    //Expand the tree and emit a newfile event
    molecule.folderTree.setExpandKeys(['0', '3'])
    //TODO: You can't expand a root folder, this is a bug in Molecule 1.0.2
    //https://github.com/DTStack/molecule/issues/764
    molecule.event.EventBus.emit('createFile', '')
  }
}
