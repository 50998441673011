import type { FW_VERSION } from "src/api/fwVersion"

/**
 * TODO: replace commit
 */
export const parseVersionFromRawStr = (str: string): FW_VERSION => {
  const versionString = str.match(/\d+\.\d+\.\d+/)[0]
  const [major, minor, patch] = versionString.split('.').map(Number)
  return { "major": major, "minor": minor, "patch": patch, 'raw': str, 'commit': '#666' }
}

/**
 * Function compares versions if v1 <= v2
 */
export const lesserEqual = (version1, version2) => {
  return ((
    version1.major <= version2.major &&
    version1.minor <= version2.minor &&
    version1.patch <= version2.patch)
    ||
    (
      version1.major < version2.major
    )
    ||
    (
      version1.major == version2.major &&
      version1.minor < version2.minor
    )
    ||
    (
      version1.major == version2.major &&
      version1.minor == version2.minor &&
      version1.patch < version2.patch
    ))
}