import FrameTypes, { Frame } from './frameTypes'
import { AcriosSerial } from './serialDriver'
import { formatBytes } from './helpers'
import { FW_VERSION } from './fwVersion'
import xzCompressorDecompressor from './xz/xz'
import { Buffer } from 'buffer'
import { MAGIC, aesEncrypt } from './crypto/crypto'
import { LuaWriteParams } from 'src/types'

const FRAME_LENGTH = 51
const SCRATCHPAD_SIZE = 128
const CHUNK_SIZE = 32
const MAX_LUA_SIZE = 2 * 16384

enum LUA_SCRIPT_MODE {
  TEXT, // just text
  XZ, // compresed text
  EXZ, // encrypted compressed text
}

const getByte = (data, bytenum) => {
  return (data >> (8 * bytenum)) & 0xff
}

const sendFrame = async (
  _config,
  _data,
  verbose = true,
  port: AcriosSerial
): Promise<boolean> => {
  if (verbose) console.log('✉️ Sending frame: ', _config)

  //Reduce function for int8array sum
  const sum = (previousValue, currentValue) => {
    return previousValue + currentValue
  }

  //Format frame
  const chunks = []
  chunks.push(Buffer.from('CONFIG', 'utf8')) // "CONFIG"
  chunks.push(Buffer.from(new Uint8Array([(_config.id >> 0) & 0xff]))) // <ID>

  if (_config.payload) {
    _data = _config.payload
  }

  //Create data+checksum (if data are supplied)
  if (_data) {
    const sum_ = (_data.reduce(sum) + (_config.id >> 0)) & 0xff
    const chs = (sum_ >> 0) & 0xff
    chunks.push(Buffer.from(_data))
    chunks.push(Buffer.from(new Uint8Array([chs])))
  }

  //Concat buffer to create payload
  const data = Buffer.concat(chunks)
  let buffer
  if (data.length < 51) {
    //Fill the rest with 0xFF if necessary
    const remaining = Buffer.alloc(FRAME_LENGTH - data.length)
    for (let index = 0; index < remaining.length; index++) {
      remaining[index] = 0xff
    }

    //Create payload and send
    buffer = Buffer.concat([data, remaining])
  } else {
    buffer = data
  }

  try {
    await port.write(buffer)
    return true
  } catch (error) {
    console.error('[SEND FRAME ERROR] Failed while writing to the port.', error)
    return false
  }
}

type WriteLuaPayload = {
  frames?: Frame[]
  length?: number
  error?: string
}

/**
 * Prepare frames for writing Lua code to a device
 * @param bytecode Binary compiled Lua script
 * @param source Lua script
 * @param sourceMode Text or XZ
 * @returns
 */
const prepareWriteLuaPayload = async (
  bytecode: Uint8Array,
  source: string = null,
  fwVersion: FW_VERSION,
  params: LuaWriteParams
): Promise<WriteLuaPayload> => {
  let payload
  let output

  const sourceMode = params.encryptScript
    ? LUA_SCRIPT_MODE.EXZ
    : LUA_SCRIPT_MODE.XZ

  //<ESCAPE><BYTECODE_SIZE><XZ_SIZE><LUA_BYTECODE><XZ_LUA>

  try {
    output = await xzCompressorDecompressor({
      input: source,
      doDecompression: false,
    })
  } catch (error) {
    console.error('[SERIAL API] ' + error)
    return { error: error }
  }

  if (params.withoutScript) {
    payload = [
      // escape header
      // new Uint8Array([0x02, 0xfe, 0xfe, 0xfe]),
      new Uint8Array([0x01, 0xfe, 0xfe, 0xfe]),
      // bytecode size
      new Uint8Array([
        getByte(bytecode.length, 0),
        getByte(bytecode.length, 1),
        getByte(bytecode.length, 2),
        getByte(bytecode.length, 3),
      ]),
      // XZ size
      new Uint8Array([0x00, 0x00, 0x00, 0x00]),
      // bytecode
      bytecode,
      // XZ lua
      new Uint8Array([]),
    ]
  } else {
    const xzLua = new Uint8Array(output)
    let xzSize
    let luaScript
    let header

    if (sourceMode === LUA_SCRIPT_MODE.EXZ) {
      const xzLuaWithMagic = new Uint8Array([...MAGIC, ...output])
      header = new Uint8Array([0x02, 0xfe, 0xfe, 0xfe])
      const encryptedXZByteArray = await aesEncrypt(
        xzLuaWithMagic,
        params.password
      )

      xzSize = new Uint8Array([
        getByte(encryptedXZByteArray.length, 0),
        getByte(encryptedXZByteArray.length, 1),
        getByte(encryptedXZByteArray.length, 2),
        getByte(encryptedXZByteArray.length, 3),
      ])
      luaScript = encryptedXZByteArray
    } else {
      header = new Uint8Array([0x01, 0xfe, 0xfe, 0xfe])
      xzSize = new Uint8Array([
        getByte(xzLua.length, 0),
        getByte(xzLua.length, 1),
        getByte(xzLua.length, 2),
        getByte(xzLua.length, 3),
      ])
      luaScript = xzLua
    }

    payload = [
      // escape header
      header,
      // bytecode size
      new Uint8Array([
        getByte(bytecode.length, 0),
        getByte(bytecode.length, 1),
        getByte(bytecode.length, 2),
        getByte(bytecode.length, 3),
      ]),
      // XZ size
      xzSize,
      // bytecode
      bytecode,
      // XZ/EXZ lua
      luaScript,
    ]
  }

  // Get the total length of all arrays.
  let length = 0
  payload.forEach((item) => {
    length += item.length
  })

  // Create a new array with total length and merge all source arrays.
  let mergedArray = new Uint8Array(length)
  let offset = 0
  payload.forEach((item) => {
    mergedArray.set(item, offset)
    offset += item.length
  })

  let maxScratchpadCount = Math.ceil(mergedArray.length / SCRATCHPAD_SIZE)
  let maxChunkCount = Math.ceil(SCRATCHPAD_SIZE / CHUNK_SIZE)

  console.table({
    binary: { Bytes: bytecode.length, Human: formatBytes(bytecode.length) },
    script: { Bytes: output.length, Human: formatBytes(output.length) },
    total: {
      Bytes: mergedArray.length,
      Human: formatBytes(mergedArray.length),
    },
    scratchpads: maxScratchpadCount,
  })

  // workaround the issue with alignment to scratchpads for older firmwares than 2.8
  if (fwVersion.major === 2 && fwVersion.minor < 8) {
    const totalScratchpadByteSize = maxScratchpadCount * SCRATCHPAD_SIZE
    if (totalScratchpadByteSize - mergedArray.length <= 8) {
      console.error(
        `Total size is too close to multiple of scratchpad size (${totalScratchpadByteSize})! Will append current datetime to the end of script to prevent issue with older firmwares than 2.8.x.`
      )
      return await prepareWriteLuaPayload(
        bytecode,
        `${source}\n-- ${new Date().toISOString()}`,
        fwVersion,
        params
      )
    }
  }

  let frames = []
  //Prepare chunks
  for (let i = 0; i < maxScratchpadCount; i++) {
    //Define chunk from mergedarray range
    let chunk = mergedArray.slice(
      i * SCRATCHPAD_SIZE,
      (i + 1) * SCRATCHPAD_SIZE
    )

    let chunkValidLength = chunk.length
    //Align scratchpad size for smaller chunks
    if (chunk.length !== SCRATCHPAD_SIZE) {
      let remainingSize = SCRATCHPAD_SIZE - chunk.length
      let remaining = new Uint8Array(remainingSize)

      for (let index = 0; index < remaining.length; index++) {
        remaining[index] = 0xff
      }

      let filled = new Uint8Array(chunk.length + remainingSize)
      filled.set(chunk, 0)
      chunk = filled
    }

    //Prepare frames for scratchpad
    for (let index = 0; index < maxChunkCount; index++) {
      let x
      if (index * CHUNK_SIZE + CHUNK_SIZE < chunk.length) {
        x = chunk.slice(index * CHUNK_SIZE, index * CHUNK_SIZE + CHUNK_SIZE)
      } else {
        x = new Uint8Array(CHUNK_SIZE)
        x.set(chunk.slice(index * CHUNK_SIZE), 0)
      }

      //Counter
      let mer1 = new Uint8Array([
        getByte(index * CHUNK_SIZE, 0),
        getByte(CHUNK_SIZE, 0),
      ])
      let mer = new Uint8Array(x.length + 2)
      mer.set(mer1, 0)
      mer.set(x, 2)

      //Frame number
      frames.push({ ...FrameTypes.WRITE_TO_SCRATCHPAD, payload: mer })
    }
    //Move to scratchpad to
    const fireFrame = new Uint8Array([
      0,
      getByte(i, 0),
      getByte(i, 1),
      getByte(chunkValidLength, 0),
      getByte(chunkValidLength, 1),
    ])
    frames.push({ ...FrameTypes.MOVE_LUA_SCRIPT_TO_FLASH, payload: fireFrame })
  }

  // handle corner-case: if we have exactly length multiple of 128, then write empty at the end..
  if (maxScratchpadCount === mergedArray.length / SCRATCHPAD_SIZE) {
    const fireFrame = new Uint8Array([
      0,
      getByte(maxScratchpadCount + 1, 0),
      getByte(maxScratchpadCount + 1, 1),
      getByte(0, 0),
      getByte(0, 1),
    ])
    frames.push({ ...FrameTypes.MOVE_LUA_SCRIPT_TO_FLASH, payload: fireFrame })
  }

  return {
    frames,
    length: mergedArray.length,
  }
}

/**
 * Prepare frames for reading from one scratchpad defined by its index number
 *
 * @param scratchpad
 * @returns
 */
const prepareReadLuaPayload = (scratchpad: number = 0): Frame[] => {
  let arr: Frame[] = []

  let mer = new Uint8Array([0, getByte(scratchpad, 0), getByte(scratchpad, 1)])
  arr.push({ ...FrameTypes.MOVE_LUA_SCRIPT_TO_SCRATCHPAD, payload: mer })

  //Cycle through the chunks
  for (let i = 0; i < Math.ceil(SCRATCHPAD_SIZE) / CHUNK_SIZE; i++) {
    let chunkPayload = new Uint8Array([i * CHUNK_SIZE, CHUNK_SIZE, 0, 0, 0])
    arr.push({ ...FrameTypes.READ_FROM_SCRATCHPAD, payload: chunkPayload })
  }

  console.log('Starting from scratchpad #', scratchpad)

  return arr
}

export {
  CHUNK_SIZE,
  SCRATCHPAD_SIZE,
  LUA_SCRIPT_MODE,
  sendFrame,
  prepareWriteLuaPayload,
  prepareReadLuaPayload,
}
