import { createGlobalState } from 'react-hooks-global-state'
import { FW_VERSION } from 'src/api/fwVersion'
import { LuaApiFunction } from 'src/types'

/**
 * App global state
 *
 * Using state object only one-level deep
 */
export const { useGlobalState } = createGlobalState({
  // editor
  outputPanelHeight: 700,
  outputPanelAutoScroll: true,

  // bootloader
  bootLoaderLegacy: false,

  // device info
  deviceDetected: false,
  deviceType: '',
  deviceFwVersion: {} as FW_VERSION,

  // local echo for terminal behaviour
  localEchoTerm: null,
})
