import molecule from '@dtinsight/molecule'

enum ACR_BAUDRATES {
  BOOTLOADER = 921600,
  COMMUNICATION = 115200,
}

const defaultSerialOptions: SerialOptions = {
  baudRate: ACR_BAUDRATES.COMMUNICATION,
  dataBits: 8,
  stopBits: 1,
  parity: 'none',
  bufferSize: 512,
  flowControl: 'none',
}

const defaultSerialFilters: SerialPortRequestOptions = {
  filters: [
    // Future Technology Devices International, Ltd Bridge(I2C/SPI/UART/FIFO)
    { usbVendorId: 0x0403, usbProductId: 0x6015 },
    // Future Technology Devices International, Ltd FT232 Serial (UART) IC
    { usbVendorId: 0x0403, usbProductId: 0x6001 },
  ],
}

const MAX_ERROR_COUNT = 3

class AcriosSerial {
  serialOptions: SerialOptions
  serialFilters: SerialPortRequestOptions
  port: SerialPort | null
  EOF: string
  encoder: TextEncoderStream
  decoder: TextDecoderStream
  lineTransformer: TransformStream
  writeToStream: WritableStream
  readFromStream: ReadableStream
  reader: ReadableStreamDefaultReader | null
  readTransformers: Array<TextDecoderStream | TransformStream>
  isConnected: boolean
  errorCount: number

  constructor() {
    this.serialOptions = defaultSerialOptions
    this.serialFilters = defaultSerialFilters

    this.port = null
    this.isConnected = false
    this.errorCount = 0
  }

  // TODO: handle corner case when user has two UART-USBs, one of them is paired, but user wants to connect to second one. So we need special button to force port select UI.
  // TODO: on Ubuntu user has to be in groups dialup (`sudo adduser [username] dialout` and restart)
  async connect(): Promise<boolean> {
    try {
      // list previously paired ports
      const pairedPorts = await navigator.serial.getPorts()
      console.log("[SERAIL] Paired ports:")
      console.log(pairedPorts)
      
      if (pairedPorts.length === 1) {
        const portInfo = pairedPorts[0].getInfo()
        if (
          this.serialFilters.filters.some(
            (filter) =>
              portInfo.usbVendorId === filter.usbVendorId &&
              portInfo.usbProductId === filter.usbProductId
          )
        ) {
          this.port = pairedPorts[0]
          console.info(`[SERIAL] Selecting previously paired port`, this.port)
        }
      }
    } catch (error) {
      console.error(`[SERIAL] Cannot list previously paired ports: ${error}`)
    }

    try {
      // let user to decide, if no matching port has been found or there are multiple to choose from
      if (!this.port) {
        console.info(`[SERIAL] Will show user dialog to choose serial port.`)
        this.port = await navigator.serial.requestPort(this.serialFilters)
      }
    } catch (error) {
      console.error(`[SERIAL] Cannot open port selection dialog: ${error}`)
    }

    try {
      // try to open selected port
      if (this.port) {
        console.info(`[SERIAL] Will open port with options`, this.serialOptions)
        console.log("[SERIAL]")
        console.log(this.port)
        await this.port.open(this.serialOptions)
        this.isConnected = true
        this.errorCount = 0
        console.log(`[SERIAL] Port open`)
        console.log('[SERIAL DRIVER] < connect() -> true')
        return true
      }
    } catch (error) {
      console.error(`[SERIAL] ${error}`)
    }

    console.log('[SERIAL DRIVER] < connect() -> false')

    return false
  }

  async disconnect(): Promise<boolean> {
    console.log('[SERIAL DRIVER] > disconnect()')

    try {
      if (this.reader) {
        await this.reader.cancel()
        this.reader = null
      }

      if (this.writeToStream) {
        await this.writeToStream.getWriter().close()
        this.writeToStream = null
      }
    } catch (error) {
      console.error(`[SERIAL] Error with streams: ${error}`)
    }

    try {
      await this.port.close()
      console.log(`[SERIAL] Port closed`)
    } catch (error) {
      console.error(`[SERIAL] Could not close port: ${error}`)
    } finally {
      this.port = null
      this.isConnected = false
    }

    console.log('[SERIAL DRIVER] < disconnect()')

    return true
  }

  async switchBaudrate(baudRate: ACR_BAUDRATES): Promise<void> {
    console.log('[SERIAL DRIVER] > switchBaudrate()')

    console.log(`[SERIAL] Switching baudrate to ${baudRate}`)

    await this.disconnect()

    console.log('[SERIAL DRIVER] switchBaudrate() after disconnect()')

    this.serialOptions = {
      ...defaultSerialOptions,
      baudRate: baudRate,
    }

    await this.connect()
    console.log('[SERIAL DRIVER] < switchBaudrate()')
  }

  async write(
    cmd: Uint8Array,
    delayBetweenChars: boolean = false
  ): Promise<void> {
    try {
      if (this.port?.writable) {
        if (this.port.writable.locked === true) {
          console.error('[SERIAL/WRITE] Port is locked')
          return
        }
        const writer = this.port.writable.getWriter()

        if (delayBetweenChars === false) {
          // send all at once
          await writer.write(cmd)

          writer.releaseLock()
          this.errorCount = 0
        } else {
          // inject delay between each character
          // needed for old version of lua interactive mode mostly
          let i = 0
          let interval = setInterval(async () => {
            if (i >= cmd.length) {
              clearInterval(interval)
              writer.releaseLock()
              return
            }

            try {
              await writer.write(cmd.slice(i, i + 1))
              i += 1
            } catch (ex) {
              clearInterval(interval)
              writer.releaseLock()
              console.error(`[WRITE] Could not write to port: ${ex}`, cmd)
            }
          }, 5)
        }
      }
    } catch (ex) {
      console.error(`[WRITE] Could not write to port: ${ex}`, cmd)
      if (this.errorCount > MAX_ERROR_COUNT) {
        await this.disconnect()
      }
      this.errorCount += 1
    }
  }

  async *readLineGenerator(): AsyncGenerator<
    { value: any; done: boolean },
    void,
    unknown
  > {
    while (this.port && this.port.readable) {
      try {
        this.reader = this.port.readable.getReader()
        while (true) {
          const { value, done } = await this.reader.read()

          if (value) {
            molecule.event.EventBus.emit('DataReceived', value)
          }

          yield { value, done }
        }
      } catch (e) {
        console.error(`[ERROR] ${e}`)
      } finally {
        this.reader.releaseLock()
        this.reader = undefined
      }
    }
  }
}

export { ACR_BAUDRATES, AcriosSerial }
