import { Buffer } from 'buffer'

import { sendFrame } from './serialApi'
import FrameTypes from './frameTypes'

import { EVENT_TYPE } from './serialProvider'

import { isWhatPercentOf } from './helpers'
import { AcriosSerial, ACR_BAUDRATES } from './serialDriver'
import { NotificationLevel, showNotification } from '../common'
import {
  acrComReceiverGetState,
  acrComReceiverGetReceivedFrame,
  acrComReceiverStart,
  acrComReceiverFSM,
  ACRCOM_RECEIVER_STATE,
  crc16_calculate_algorithm,
  acrComSendFramedData,
} from './acrCom'

enum BOOTLOADER_STATE {
  IDLE = 'idle',
  WAIT_FOR_BEACON_REQUEST = 'wait-for-beacon-request',
  RESET_FROM_APP = 'reset-from-app',
  WAIT_FOR_STATE_ANSWER = 'wait-for-state-answer',
  SENDING_PAGE = 'sending-page',
  SENDING_PAGE_INTER_FRAME_WAIT = 'sending-page-inter-frame-wait',
  WAIT_FOR_FLUSH_ANSWER = 'wait-for-flush-answer',
  SENDING_MISSING_FRAGMENTS = 'sending-missing-fragments',
  SENDING_MISSING_FRAGMENTS_INTER_FRAME_WAIT = 'sending-missing-fragments-inter-frame-wait',
  SEND_BOOT_NOW = 'send-boot-now',
  DONE = 'done',
  ERROR = 'error',

  // states used for probing
  WAIT_FOR_BEACON_REQUEST_THEN_BOOT = 'wait-for-beacon-request-then-boot',
}

enum BOOTLOADER_FRAME_TYPE {
  FT_BEACON_REQ = 0xb5,
  FT_BEACON_ANS = 0x5b, // TX ->
  FT_PUSH_DATA = 0xd5, // TX -> load fragment command
  FT_PAGE_FLUSH_REQ = 0xe5, // TX -> page write through command
  FT_PAGE_FLUSH_ANS = 0x5e,
  FT_RESET_REQ = 0x55, // TX ->
  FT_BEACON_BOOT_REQ = 0xbb, // TX ->
  FT_STATE_ANS = 0xec,
  FT_INVALID = 0xff,
}

// Bootloader layer framing:
// uint8 - MHDR  = 0xE0 constant
// uint8 - crc8 - CRC of what follows
// uint8[8] - devEUI
// uint8 - frame type, we support: FT_BEACON_REQ, FT_BEACON_ANS, FT_PUSH_DATA, FT_PAGE_FLUSH_REQ, FT_PAGE_FLUSH_ANS, FT_RESET_REQ, FT_BEACON_BOOT_REQ
// -> based on frame type

const PAGE_SIZE = 2048.0
const SKIP_BOOTLOADER = 16 * 1024
const FRAGMENT_SIZE = 128

const MAX_ERRORS_IN_ROW = 20
const MAX_WAIT_FOR_BEACON_TIMEOUT = 12000
const MAX_WAIT_FOR_STATE_ANSWER_TIMEOUT = 2000
const MAX_WAIT_FOR_FLUSH_ANSWER_TIMEOUT = 3000
const MAX_SEND_BOOT_NOW_TIMEOUT = 200
const MAX_SEND_BOOT_NOW_COUNT = 5
const MAX_SEND_APP_RESET_TIMEOUT = 100
const MAX_SEND_APP_RESET_COUNT = 4
const MAX_FRAME_TIMEOUT = 300
const MIN_INTER_FRAME_DELAY = 10

const bootloaderCtx = {
  state: BOOTLOADER_STATE.IDLE,
  resetSentTimestamp: 0,
  beaconAnswerSentTimestamp: 0,
  currentFragments: [],
  currentMissingFragments: [],
  currentFragment: 0,
  currentPageAddress: 0,
  sentFragmentTimestamp: 0,
  sentFlushTimestamp: 0,
  sentBootNowTimestamp: 0,
  sentBootNowCounter: 0,
  sentAppResetTimestamp: 0,
  sentAppResetCounter: 0,

  lastReceivedState: {
    mainState: 0,
    hasValidFirmware: 0,
    bootloaderVersionMajor: 0,
    bootloaderVersionMinor: 0,
    lastBaseAddr: 0,
    rxedPages: 0,
    snr: 0,
    rssi: 0,
  },
  useBroadcast: true,
  broadcastEUIUint8: new Uint8Array([
    0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff,
  ]),
  receivedEUIString: '',
  receivedEUIUint8: null,
  receivedFrameType: BOOTLOADER_FRAME_TYPE.FT_INVALID,
  frameReceiveTimestamp: 0,
  receiverExpectedLength: 0,
  receiverExpectedCrc16: 0,

  receivedFrame: [],
  errorsInRow: 0,
  currentPage: 0,
  pagesOrder: [],
  totalPages: 0,
  fw: [],
}

const crc8 = (inCrc: number, inData: number): number => {
  let data = inCrc ^ inData

  for (let i = 0; i < 8; i += 1) {
    if ((data & 0x80) !== 0) {
      data = data << 1
      data = data ^ 0x07
    } else {
      data = data << 1
    }
  }

  return data & 0xff
}

const crc8Block = (data: Uint8Array): number => {
  let crc = 0xaa
  let i = 0
  let length = data.byteLength
  while (length > 0) {
    crc = crc8(crc, data[i])
    i += 1
    length -= 1
  }

  return crc
}

const byteOf = (inp: number, byteNumber: number): number => {
  return (inp >> (byteNumber * 8)) & 0xff
}

const sendShortCommand = async (
  port,
  devEUI: Uint8Array,
  frameType: BOOTLOADER_FRAME_TYPE
) => {
  // uint8 - MHDR  = 0xE0 constant
  // uint8 - crc8 - CRC of what follows
  // uint8[8] - devEUI
  // uint8 - frame type,

  const chunksCRCed = []
  chunksCRCed.push(Buffer.from(devEUI))
  chunksCRCed.push(Buffer.from(new Uint8Array([byteOf(frameType, 0)])))
  const CRCed = Buffer.concat(chunksCRCed)

  let crc8Value = crc8Block(CRCed)
  let cmd = new Uint8Array([0x0e, byteOf(crc8Value, 0)])

  const chunks = []
  chunks.push(Buffer.from(cmd))
  chunks.push(CRCed)
  const rawCommand = Buffer.concat(chunks)

  return await acrComSendFramedData(port, rawCommand)
}

const sendBeaconAnswer = async (port, devEUI: Uint8Array) => {
  console.log(
    `[BOOTLOADER] Send beacon answer to EUI ${bootloaderCtx.receivedEUIString}`
  )
  // uint8 - MHDR  = 0xE0 constant
  // uint8 - crc8 - CRC of what follows
  // uint8[8] - devEUI
  // uint8 - frame type, = FT_BEACON_ANS

  // FT_BEACON_ANS:
  // uint32 - 0xA5A5A5A5 - fixed constant
  // uint8 - 0 - fixed constant
  // uint8 - 128 - fixed constant, size of chunk se to maximum, 128 bytes
  // uint32 - 921600 - fixed constant, baudrate to use during bootloading
  // uint16 - 0 - fixed constant
  // uint8 - 0 - fixed constant
  // uint8 - 0 - fixed constant
  // uint8 - 0 - fixed constant
  // uint8 - 0 - fixed constant
  // uint8 - 0/2 - fixed constant, 0 for now, 2 later to enable de-ciphering
  // uint8[16] - nonce, used when previous is 2, used during ciphering, taken from filename
  // uint32 - 0 - fixed constant

  const chunksCRCed = []
  const baudrate = 921600
  chunksCRCed.push(Buffer.from(devEUI))
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([byteOf(BOOTLOADER_FRAME_TYPE.FT_BEACON_ANS, 0)])
    )
  )
  chunksCRCed.push(
    Buffer.from(new Uint8Array([0xa5, 0xa5, 0xa5, 0xa5, 0x00, FRAGMENT_SIZE]))
  )
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([
        byteOf(baudrate, 0),
        byteOf(baudrate, 1),
        byteOf(baudrate, 2),
        byteOf(baudrate, 3),
      ])
    )
  )
  chunksCRCed.push(
    Buffer.from(new Uint8Array([0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x02]))
  ) // last byte is 0x02, when cipering enabled, now 0x00
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([
        0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
        0x00, 0x00, 0x00, 0x00,
      ])
    )
  ) // nonce, for now all zeroes
  chunksCRCed.push(Buffer.from(new Uint8Array([0x00, 0x00, 0x00, 0x00])))
  const CRCed = Buffer.concat(chunksCRCed)

  let crc8Value = crc8Block(CRCed)
  let cmd = new Uint8Array([0x0e, byteOf(crc8Value, 0)])

  const chunks = []
  chunks.push(Buffer.from(cmd))
  chunks.push(CRCed)
  const rawCommand = Buffer.concat(chunks)

  return await acrComSendFramedData(port, rawCommand)
}

// const sendResetRequest = (port, devEUI: Uint8Array) => {
//   console.log(`[BOOTLOADER] Send reset request to EUI ${bootloaderCtx.receivedEUIString}`);
//   return sendShortCommand(port, devEUI, BOOTLOADER_FRAME_TYPE.FT_RESET_REQ);
// }

const sendBootRequest = (port, devEUI: Uint8Array) => {
  console.log(
    `[BOOTLOADER] Send boot request to EUI ${bootloaderCtx.receivedEUIString}`
  )
  return sendShortCommand(
    port,
    devEUI,
    BOOTLOADER_FRAME_TYPE.FT_BEACON_BOOT_REQ
  )
}

const sendPushPageFragmentData = async (
  port,
  devEUI: Uint8Array,
  fragment: Uint8Array,
  flashAddr: number
) => {
  flashAddr += SKIP_BOOTLOADER

  //console.log(`[BOOTLOADER] Send push data to EUI ${bootloaderCtx.receivedEUIString}, address = 0x${flashAddr.toString(16)}`);

  // uint8 - MHDR  = 0xE0 constant
  // uint8 - crc8 - CRC of what follows
  // uint8[8] - devEUI
  // uint8 - frame type, = FT_PUSH_DATA
  // FT_PUSH_DATA:
  // uint32 - flashAddr - absolute flash address of the fragment
  // uint8[128] - fragment of file to flash

  const chunksCRCed = []
  chunksCRCed.push(Buffer.from(devEUI))
  chunksCRCed.push(
    Buffer.from(new Uint8Array([byteOf(BOOTLOADER_FRAME_TYPE.FT_PUSH_DATA, 0)]))
  )
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([
        byteOf(flashAddr, 0),
        byteOf(flashAddr, 1),
        byteOf(flashAddr, 2),
        byteOf(flashAddr, 3),
      ])
    )
  )
  chunksCRCed.push(Buffer.from(fragment))
  const CRCed = Buffer.concat(chunksCRCed)

  let crc8Value = crc8Block(CRCed)
  let cmd = new Uint8Array([0x0e, byteOf(crc8Value, 0)])

  const chunks = []
  chunks.push(Buffer.from(cmd))
  chunks.push(CRCed)
  const rawCommand = Buffer.concat(chunks)

  return await acrComSendFramedData(port, rawCommand)
}

const sendPageFlushRequest = async (
  port,
  devEUI: Uint8Array,
  baseAddr: number,
  page: number[]
) => {
  baseAddr += SKIP_BOOTLOADER

  console.log(
    `[BOOTLOADER] Send flush page request to EUI ${
      bootloaderCtx.receivedEUIString
    }, address = 0x${baseAddr.toString(16)}`
  )
  // uint8 - MHDR  = 0xE0 constant
  // uint8 - crc8 - CRC of what follows
  // uint8[8] - devEUI
  // uint8 - frame type, = FT_PAGE_FLUSH_REQ
  // FT_PAGE_FLUSH_REQ:
  // uint32 - baseAddr - base address of the page to flash
  // uint32 - totalUsedLength - used length of the page (max value is page size)
  // uint16 - pageCrc16 - CRC16 of the entire page to write

  const chunksCRCed = []
  const totalUsedLength = PAGE_SIZE // always padd to full page size

  if (page.length !== PAGE_SIZE) {
    console.error('[BOOTLOADER] Internal error - last page not padded?')
    return
  }

  const crc16Value = crc16_calculate_algorithm(new Uint8Array(page))
  chunksCRCed.push(Buffer.from(devEUI))
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([byteOf(BOOTLOADER_FRAME_TYPE.FT_PAGE_FLUSH_REQ, 0)])
    )
  )
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([
        byteOf(baseAddr, 0),
        byteOf(baseAddr, 1),
        byteOf(baseAddr, 2),
        byteOf(baseAddr, 3),
      ])
    )
  )
  chunksCRCed.push(
    Buffer.from(
      new Uint8Array([
        byteOf(totalUsedLength, 0),
        byteOf(totalUsedLength, 1),
        byteOf(totalUsedLength, 2),
        byteOf(totalUsedLength, 3),
      ])
    )
  )
  chunksCRCed.push(
    Buffer.from(new Uint8Array([byteOf(crc16Value, 0), byteOf(crc16Value, 1)]))
  )

  const CRCed = Buffer.concat(chunksCRCed)

  let crc8Value = crc8Block(CRCed)
  let cmd = new Uint8Array([0x0e, byteOf(crc8Value, 0)])

  const chunks = []
  chunks.push(Buffer.from(cmd))
  chunks.push(CRCed)
  const rawCommand = Buffer.concat(chunks)

  return await acrComSendFramedData(port, rawCommand)
}

const applicationSendResetDevice = async (port): Promise<boolean> => {
  console.log(`[BOOTLOADER] Send application protocol reset`)
  return await sendFrame(FrameTypes.RESET_BOARD, null, false, port.current)
}

const serialBootloaderStart = async (fw) => {
  // cache file to upload as buffer
  bootloaderCtx.fw = fw

  bootloaderCtx.totalPages = Math.ceil(fw.length / PAGE_SIZE)

  // reset state machine to default state
  bootloaderCtx.errorsInRow = 0
  bootloaderCtx.currentPage = 0

  bootloaderCtx.resetSentTimestamp = new Date().valueOf()
  bootloaderCtx.state = BOOTLOADER_STATE.RESET_FROM_APP
  bootloaderCtx.sentAppResetCounter = 0
  bootloaderCtx.sentAppResetTimestamp = new Date().valueOf()

  acrComReceiverStart(MAX_FRAME_TIMEOUT)

  // externally: call FSM serialBootloaderFSM() function
  // in periodic tick (1 or 10 or 100 ms) tick is OK
  // in on receive event passing received bytes data as buffer in eventData
}

const serialBootloaderSendBootCommand = async (port) => {
  // reset state machine to "send boot" starting state
  bootloaderCtx.state = BOOTLOADER_STATE.WAIT_FOR_BEACON_REQUEST_THEN_BOOT
  bootloaderCtx.frameReceiveTimestamp = 0
  await port.current.switchBaudrate(ACR_BAUDRATES.BOOTLOADER)
  acrComReceiverStart(MAX_FRAME_TIMEOUT)
}

const serialBootloaderGetState = () => {
  return bootloaderCtx.state
}

// adds padding if not multiple of pageSize
const getPage = (
  fw: number[],
  pageIndex: number,
  pageSize: number = PAGE_SIZE
): number[] => {
  if ((pageIndex + 1) * pageSize <= fw.length) {
    // no padding needed
    return fw.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
  } else if (pageIndex * pageSize >= fw.length) {
    // out of range -> return empty
    return []
  } else {
    // hits the end - padd with 0xFFs
    let ret = fw.slice(pageIndex * pageSize)

    if (ret.length < pageSize) {
      let toAdd = []
      while (toAdd.length + ret.length < pageSize) {
        toAdd.push(0xff)
      }
      const chunks = []
      chunks.push(Buffer.from(ret))
      chunks.push(Buffer.from(new Uint8Array(toAdd)))
      let concated = Buffer.concat(chunks)

      let toReturn: number[] = []
      for (let i = 0; i < concated.length; i += 1) {
        toReturn.push(concated.readUInt8(i))
      }
      return toReturn
    }
    return ret
  }
}

// create list of Fragments from page based on list
const getFragments = (
  page: number[],
  fragments: number[],
  fragmentSize: number = FRAGMENT_SIZE
): number[][] => {
  let ret = []
  for (let i = 0; i < fragments.length; i += 1) {
    ret.push(
      page.slice(fragments[i] * fragmentSize, (fragments[i] + 1) * fragmentSize)
    )
  }
  return ret
}

const getFragmentsForPage = (
  fw: number[],
  pageIndex: number,
  pageSize: number = PAGE_SIZE,
  fragmentSize: number = FRAGMENT_SIZE
): number[][] => {
  const page = getPage(fw, pageIndex, pageSize)

  let fragmentsList = []
  for (let i = 0; i < pageSize; i += fragmentSize) {
    fragmentsList.push(i / fragmentSize)
  }

  const fragments = getFragments(page, fragmentsList, fragmentSize)
  return fragments
}

const serialBootloaderFSM = async (
  port: React.MutableRefObject<AcriosSerial>,
  event: EVENT_TYPE,
  onFinishedCallback: Function,
  eventData?
) => {
  const now = new Date().valueOf()
  // const previousState = bootloaderCtx.state;

  await acrComReceiverFSM(port, event, eventData)

  const receiverState = acrComReceiverGetState()
  if (receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
    // Bootloader layer framing:
    // uint8 - MHDR  = 0xE0 constant
    // uint8 - crc8 - CRC of what follows
    // uint8[8] - devEUI
    // uint8 - frame type, we support: FT_BEACON_REQ, FT_BEACON_ANS, FT_PUSH_DATA, FT_PAGE_FLUSH_REQ, FT_PAGE_FLUSH_ANS, FT_RESET_REQ, FT_BEACON_BOOT_REQ
    const payloadCandidate = acrComReceiverGetReceivedFrame()
    let eui = ''
    for (let i = 0; i < 8; i += 1) {
      eui += payloadCandidate[2 + i].toString(16).padStart(2, '0')
    }
    bootloaderCtx.receivedEUIString = eui
    bootloaderCtx.receivedEUIUint8 = new Uint8Array(
      payloadCandidate.slice(2, 10)
    )
    bootloaderCtx.receivedFrameType =
      payloadCandidate[10] as BOOTLOADER_FRAME_TYPE
    bootloaderCtx.receivedFrame = payloadCandidate
  }

  switch (bootloaderCtx.state) {
    case BOOTLOADER_STATE.WAIT_FOR_BEACON_REQUEST_THEN_BOOT:
      if (receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
        acrComReceiverStart(MAX_FRAME_TIMEOUT)

        // received a frame!
        // send boot now!
        await sendBootRequest(port, bootloaderCtx.receivedEUIUint8)
        return true
      }
      return false

    case BOOTLOADER_STATE.ERROR:
      await port.current.switchBaudrate(ACR_BAUDRATES.COMMUNICATION)
      onFinishedCallback()
      break
    case BOOTLOADER_STATE.IDLE:
      break
    case BOOTLOADER_STATE.WAIT_FOR_BEACON_REQUEST:
      if (receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
        // received a frame!
        if (
          bootloaderCtx.receivedFrameType ===
          BOOTLOADER_FRAME_TYPE.FT_BEACON_REQ
        ) {
          // send beacon answer
          sendBeaconAnswer(port, bootloaderCtx.receivedEUIUint8)
          bootloaderCtx.beaconAnswerSentTimestamp = now
          bootloaderCtx.errorsInRow = 0
          bootloaderCtx.state = BOOTLOADER_STATE.WAIT_FOR_STATE_ANSWER
        }

        acrComReceiverStart(MAX_FRAME_TIMEOUT)
      } else {
        if (
          now - bootloaderCtx.resetSentTimestamp >=
          MAX_WAIT_FOR_BEACON_TIMEOUT
        ) {
          if (bootloaderCtx.errorsInRow < MAX_ERRORS_IN_ROW) {
            bootloaderCtx.errorsInRow += 1
            bootloaderCtx.resetSentTimestamp = now
            await port.current.switchBaudrate(ACR_BAUDRATES.COMMUNICATION)
            bootloaderCtx.state = BOOTLOADER_STATE.RESET_FROM_APP
            bootloaderCtx.sentAppResetCounter = 0
            bootloaderCtx.sentAppResetTimestamp = now
          } else {
            showNotification(
              'Bootloader',
              `Firmware update failed!`,
              'desktop-download'
            )
            bootloaderCtx.state = BOOTLOADER_STATE.ERROR
          }
        }
      }
      break

    case BOOTLOADER_STATE.RESET_FROM_APP:
      if (
        now - bootloaderCtx.sentAppResetTimestamp >=
        MAX_SEND_APP_RESET_TIMEOUT
      ) {
        console.log(`[BOOTLOADER] Reset to bootloader now!`)
        bootloaderCtx.sentAppResetTimestamp = now
        await applicationSendResetDevice(port)
        bootloaderCtx.sentAppResetCounter += 1
        if (bootloaderCtx.sentAppResetCounter >= MAX_SEND_APP_RESET_COUNT) {
          bootloaderCtx.state = BOOTLOADER_STATE.WAIT_FOR_BEACON_REQUEST
          await port.current.switchBaudrate(ACR_BAUDRATES.BOOTLOADER)
          acrComReceiverStart(MAX_FRAME_TIMEOUT)
        }
      }
      break

    case BOOTLOADER_STATE.WAIT_FOR_STATE_ANSWER:
      if (receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
        // received a frame!
        if (
          bootloaderCtx.receivedFrameType === BOOTLOADER_FRAME_TYPE.FT_STATE_ANS
        ) {
          // TODO, parse and print version maybe here?
          // uint8_t  mainState;
          // uint8_t  hasValidFirmware;
          // uint8_t  bootloaderVersionMajor;
          // uint8_t  bootloaderVersionMinor;
          // uint32_t lastBaseAddr;
          // uint32_t rxedPages;
          // int8_t   snr;
          // int16_t  rssi;

          let mainState = bootloaderCtx.receivedFrame[11]
          let hasValidFirmware = bootloaderCtx.receivedFrame[12]
          let bootloaderVersionMajor = bootloaderCtx.receivedFrame[13]
          let bootloaderVersionMinor = bootloaderCtx.receivedFrame[14]
          let lastBaseAddr =
            bootloaderCtx.receivedFrame[15] +
            256 *
              (bootloaderCtx.receivedFrame[16] +
                256 *
                  (bootloaderCtx.receivedFrame[17] +
                    256 * bootloaderCtx.receivedFrame[18]))
          let rxedPages =
            bootloaderCtx.receivedFrame[19] +
            256 *
              (bootloaderCtx.receivedFrame[20] +
                256 *
                  (bootloaderCtx.receivedFrame[21] +
                    256 * bootloaderCtx.receivedFrame[22]))
          let snr = bootloaderCtx.receivedFrame[23]
          let rssi =
            bootloaderCtx.receivedFrame[24] +
            256 * bootloaderCtx.receivedFrame[25]

          let lastReceivedState = {
            mainState: mainState,
            hasValidFirmware: hasValidFirmware,
            bootloaderVersionMajor: bootloaderVersionMajor,
            bootloaderVersionMinor: bootloaderVersionMinor,
            lastBaseAddr: lastBaseAddr.toString(16).padStart(8, '0'),
            rxedPages: rxedPages,
            snr: snr,
            rssi: rssi,
          }

          console.log('[BOOTLOADER] state report: ', lastReceivedState)

          if (bootloaderVersionMajor * 1000 + bootloaderVersionMinor >= 1004) {
            bootloaderCtx.lastReceivedState = lastReceivedState
            bootloaderCtx.state = BOOTLOADER_STATE.SENDING_PAGE_INTER_FRAME_WAIT // start with interframe delay
            bootloaderCtx.sentFragmentTimestamp = now

            const totalPages = Math.ceil(
              (bootloaderCtx.fw.length * 1.0) / PAGE_SIZE
            )

            bootloaderCtx.pagesOrder = []
            for (let j = 1; j < totalPages; j += 1) {
              bootloaderCtx.pagesOrder.push(j)
            }
            bootloaderCtx.pagesOrder.push(0) // the first page should be flashed as the last one in order

            bootloaderCtx.currentFragment = -1
            bootloaderCtx.currentFragments = getFragmentsForPage(
              bootloaderCtx.fw,
              bootloaderCtx.pagesOrder[0]
            )
            bootloaderCtx.currentPageAddress =
              bootloaderCtx.pagesOrder[0] * PAGE_SIZE
            bootloaderCtx.currentPage = 0
            bootloaderCtx.currentMissingFragments = []
            bootloaderCtx.errorsInRow = 0
          } else {
            showNotification(
              'Bootloader',
              `Firmware update failed - too old bootloader version ${bootloaderVersionMajor}.${bootloaderVersionMinor}, please use legacy tool!`,
              'desktop-download'
            )
            bootloaderCtx.state = BOOTLOADER_STATE.ERROR
          }
        }

        acrComReceiverStart(MAX_FRAME_TIMEOUT)
      } else {
        if (
          now - bootloaderCtx.beaconAnswerSentTimestamp >=
          MAX_WAIT_FOR_STATE_ANSWER_TIMEOUT
        ) {
          if (bootloaderCtx.errorsInRow < MAX_ERRORS_IN_ROW) {
            bootloaderCtx.errorsInRow += 1
            sendBeaconAnswer(port, bootloaderCtx.receivedEUIUint8)
            bootloaderCtx.resetSentTimestamp = now
          } else {
            showNotification(
              'Bootloader',
              `Firmware update failed!`,
              'desktop-download'
            )
            bootloaderCtx.state = BOOTLOADER_STATE.ERROR
          }
        }
      }
      break

    case BOOTLOADER_STATE.SENDING_PAGE:
      //console.log(`[BOOTLOADER] Send fragment ${bootloaderCtx.currentFragment}`)
      sendPushPageFragmentData(
        port,
        bootloaderCtx.useBroadcast
          ? bootloaderCtx.broadcastEUIUint8
          : bootloaderCtx.receivedEUIUint8,
        bootloaderCtx.currentFragments[bootloaderCtx.currentFragment],
        bootloaderCtx.currentPageAddress +
          bootloaderCtx.currentFragment * FRAGMENT_SIZE
      )
      bootloaderCtx.sentFragmentTimestamp = now
      bootloaderCtx.state = BOOTLOADER_STATE.SENDING_PAGE_INTER_FRAME_WAIT
      break

    case BOOTLOADER_STATE.SENDING_PAGE_INTER_FRAME_WAIT:
      if (
        now - bootloaderCtx.sentFragmentTimestamp >=
        MIN_INTER_FRAME_DELAY * (1 + bootloaderCtx.errorsInRow)
      ) {
        bootloaderCtx.currentFragment += 1
        if (
          bootloaderCtx.currentFragment ===
          bootloaderCtx.currentFragments.length
        ) {
          sendPageFlushRequest(
            port,
            bootloaderCtx.useBroadcast
              ? bootloaderCtx.broadcastEUIUint8
              : bootloaderCtx.receivedEUIUint8,
            bootloaderCtx.currentPageAddress,
            getPage(
              bootloaderCtx.fw,
              bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
            )
          )
          bootloaderCtx.state = BOOTLOADER_STATE.WAIT_FOR_FLUSH_ANSWER
          bootloaderCtx.sentFlushTimestamp = now
          acrComReceiverStart(MAX_FRAME_TIMEOUT)
        } else {
          bootloaderCtx.state = BOOTLOADER_STATE.SENDING_PAGE
        }
      }
      break

    case BOOTLOADER_STATE.SENDING_MISSING_FRAGMENTS:
      console.log(
        `[BOOTLOADER] Send missing fragment ${
          bootloaderCtx.currentMissingFragments[bootloaderCtx.currentFragment]
        }`
      )
      sendPushPageFragmentData(
        port,
        bootloaderCtx.useBroadcast
          ? bootloaderCtx.broadcastEUIUint8
          : bootloaderCtx.receivedEUIUint8,
        bootloaderCtx.currentFragments[bootloaderCtx.currentFragment],
        bootloaderCtx.currentPageAddress +
          bootloaderCtx.currentMissingFragments[bootloaderCtx.currentFragment] *
            FRAGMENT_SIZE
      )
      bootloaderCtx.sentFragmentTimestamp = now
      bootloaderCtx.state =
        BOOTLOADER_STATE.SENDING_MISSING_FRAGMENTS_INTER_FRAME_WAIT
      break

    case BOOTLOADER_STATE.SENDING_MISSING_FRAGMENTS_INTER_FRAME_WAIT:
      if (
        now - bootloaderCtx.sentFragmentTimestamp >=
        MIN_INTER_FRAME_DELAY * (1 + bootloaderCtx.errorsInRow)
      ) {
        bootloaderCtx.currentFragment += 1
        if (
          bootloaderCtx.currentFragment ===
          bootloaderCtx.currentFragments.length
        ) {
          sendPageFlushRequest(
            port,
            bootloaderCtx.useBroadcast
              ? bootloaderCtx.broadcastEUIUint8
              : bootloaderCtx.receivedEUIUint8,
            bootloaderCtx.currentPageAddress,
            getPage(
              bootloaderCtx.fw,
              bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
            )
          )
          bootloaderCtx.state = BOOTLOADER_STATE.WAIT_FOR_FLUSH_ANSWER
          bootloaderCtx.sentFlushTimestamp = now
          acrComReceiverStart(MAX_FRAME_TIMEOUT)
        } else {
          bootloaderCtx.state = BOOTLOADER_STATE.SENDING_MISSING_FRAGMENTS
        }
      }
      break

    case BOOTLOADER_STATE.WAIT_FOR_FLUSH_ANSWER:
      if (receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
        // received a frame!
        if (
          bootloaderCtx.receivedFrameType ===
          BOOTLOADER_FRAME_TYPE.FT_PAGE_FLUSH_ANS
        ) {
          // FT_PAGE_FLUSH_ANS:
          // uint8 - missingFragmentsNum - number of missing fragments from last page
          // uint32 - baseAddr - base address, taken from last FT_PAGE_FLUSH_REQ
          // uint8[missingFragmentsNum] - missingFragments - list of missing fragments
          let missingFragmentsNum = bootloaderCtx.receivedFrame[11]
          // let baseAddr = bootloaderCtx.receivedFrame[12] + 256 * (bootloaderCtx.receivedFrame[13] + 256 * (bootloaderCtx.receivedFrame[14] + 256 * bootloaderCtx.receivedFrame[15]));
          let missingFragments = []

          for (let i = 0; i < missingFragmentsNum; i++) {
            missingFragments.push(bootloaderCtx.receivedFrame[16 + i])
          }

          bootloaderCtx.errorsInRow = 0

          if (missingFragmentsNum > 0) {
            console.log(
              `[BOOTLOADER] Flashing page ${
                bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
              }  again, mising ${missingFragmentsNum} fragments`
            )
            // create list of missing fragments, go to SENDING_MISSING_FRAGMENTS
            bootloaderCtx.currentMissingFragments = missingFragments
            bootloaderCtx.currentFragment = -1
            bootloaderCtx.currentFragments = getFragments(
              getPage(
                bootloaderCtx.fw,
                bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
              ),
              missingFragments
            )
            bootloaderCtx.state =
              BOOTLOADER_STATE.SENDING_MISSING_FRAGMENTS_INTER_FRAME_WAIT // start with interframe delay
            bootloaderCtx.sentFragmentTimestamp = now
          } else {
            // check if we are done, otherwise load next page
            bootloaderCtx.currentMissingFragments = []
            bootloaderCtx.currentPage += 1
            if (bootloaderCtx.currentPage >= bootloaderCtx.pagesOrder.length) {
              bootloaderCtx.state = BOOTLOADER_STATE.SEND_BOOT_NOW
              bootloaderCtx.sentBootNowTimestamp = now
              showNotification(
                'Bootloader',
                `Firmware update done!`,
                'desktop-download'
              )
            } else {
              showNotification(
                'Bootloader',
                `${isWhatPercentOf(
                  bootloaderCtx.currentPage,
                  bootloaderCtx.pagesOrder.length
                )}% - firmware update in progress... Keep the page open and do not disconnect the device!`,
                'desktop-download'
              )

              console.log(
                `[BOOTLOADER] Flashing page ${
                  bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
                } / ${bootloaderCtx.pagesOrder.length}`
              )
              bootloaderCtx.currentFragment = 0
              bootloaderCtx.currentFragments = getFragmentsForPage(
                bootloaderCtx.fw,
                bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
              )
              bootloaderCtx.currentPageAddress =
                bootloaderCtx.pagesOrder[bootloaderCtx.currentPage] * PAGE_SIZE
              bootloaderCtx.state = BOOTLOADER_STATE.SENDING_PAGE
            }
          }
        }

        acrComReceiverStart(MAX_FRAME_TIMEOUT)
      } else {
        if (
          now - bootloaderCtx.sentFlushTimestamp >=
          MAX_WAIT_FOR_FLUSH_ANSWER_TIMEOUT
        ) {
          bootloaderCtx.sentFlushTimestamp = now
          acrComReceiverStart(MAX_FRAME_TIMEOUT)

          if (bootloaderCtx.errorsInRow < MAX_ERRORS_IN_ROW) {
            bootloaderCtx.errorsInRow += 1
            sendPageFlushRequest(
              port,
              bootloaderCtx.useBroadcast
                ? bootloaderCtx.broadcastEUIUint8
                : bootloaderCtx.receivedEUIUint8,
              bootloaderCtx.currentPageAddress,
              getPage(
                bootloaderCtx.fw,
                bootloaderCtx.pagesOrder[bootloaderCtx.currentPage]
              )
            )
          } else {
            showNotification(
              'Bootloader',
              `Firmware update failed!`,
              'desktop-download'
            )
            bootloaderCtx.state = BOOTLOADER_STATE.ERROR
          }
        }
      }
      break

    case BOOTLOADER_STATE.SEND_BOOT_NOW:
      if (
        now - bootloaderCtx.sentBootNowTimestamp >=
        MAX_SEND_BOOT_NOW_TIMEOUT
      ) {
        console.log(`[BOOTLOADER] Boot now!`)
        bootloaderCtx.sentBootNowTimestamp = now
        sendBootRequest(port, bootloaderCtx.receivedEUIUint8)
        bootloaderCtx.sentBootNowCounter += 1
        if (bootloaderCtx.sentBootNowCounter >= MAX_SEND_BOOT_NOW_COUNT) {
          bootloaderCtx.state = BOOTLOADER_STATE.DONE
          console.log(`[BOOTLOADER] Bootload done! Switch back to normal mode!`)
          await port.current.switchBaudrate(ACR_BAUDRATES.COMMUNICATION)
          onFinishedCallback()
        }
      }
      break
    case BOOTLOADER_STATE.DONE:
      break
  }

  //if (previousReceiverState !== receiverState) {
  //  console.warn(`[BOOTLOADER] Receiver state changed ${previousReceiverState} => ${receiverState}`);
  //}

  //if (previousState !== bootloaderCtx.state) {
  //  console.warn(`[BOOTLOADER] State changed ${previousState} => ${bootloaderCtx.state}`);
  //}

  //console.log(`[BOOTLOADER] < serialBootloaderFSM(${event}, ${bootloaderCtx.state}, ${receiverState}) @ ${now}`);
}

export {
  EVENT_TYPE,
  serialBootloaderSendBootCommand,
  serialBootloaderStart,
  serialBootloaderGetState,
  serialBootloaderFSM,
}
