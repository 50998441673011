export type FW_VERSION = {
  major: number
  minor: number
  patch: number
  commit: string
  raw: string
}

// example of version string: "CV_FW_2.7.7-0-g3f900cbb599f"

const parseFwVersion = (version: string): FW_VERSION => {
  const match = version.match(/CV_FW_(\d+)\.(\d+)\.(\d+)-\d+-(.*)/)
  if (match?.length === 5) {
    const [raw, major, minor, patch, commit] = match

    const fwVersion = {
      raw,
      commit,
      major: parseInt(major, 10),
      minor: parseInt(minor, 10),
      patch: parseInt(patch, 10),
    }
    console.log(`Parsed version string ${version} to`, fwVersion)
    return fwVersion
  }

  console.warn(`Could not parse firmware version string: ${version}`)
  return {
    raw: version,
    commit: '',
    major: 0,
    minor: 0,
    patch: 0,
  }
}

export { parseFwVersion }
