import { KeybindingWeight } from '@dtinsight/molecule/esm/monaco/common'
import { Action2 } from '@dtinsight/molecule/esm/monaco/action'
import { KeyCode, KeyMod } from '@dtinsight/molecule/esm/monaco'
//@ts-ignore
import { KeyChord } from 'monaco-editor/esm/vs/base/common/keyCodes'
import molecule from '@dtinsight/molecule'

export class KeybindingAction extends Action2 {
  static readonly ID = 'AutoSave'

  constructor() {
    super({
      id: KeybindingAction.ID,
      desc: 'Save action',
      precondition: undefined,
      f1: false, // Not show in the Command Palette
      keybinding: {
        weight: KeybindingWeight.WorkbenchContrib,
        when: undefined,
        primary: KeyChord(KeyMod.CtrlCmd | KeyCode.KeyS),
      },
    })
  }

  run(accessor: any, ...args: any[]) {
    const activeTab = molecule.editor.getState().current
    if (activeTab) {
      molecule.event.EventBus.emit(
        'forceSave',
        molecule.editor.getState().current
      )
      console.log(
        'Emmiting Save for following tab :',
        molecule.editor.getState().current
      )
    } else {
      console.log('Skipping save, no current tab open')
    }
  }
}
