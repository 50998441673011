import { Buffer } from 'buffer'

// import { sendFrame } from "./serialApi"
// import FrameTypes from './frameTypes';

import { EVENT_TYPE } from './serialProvider'

import { AcriosSerial } from './serialDriver'

enum ACRCOM_RECEIVER_STATE {
  IDLE = 'idle',
  WAIT_FOR_START_BYTE = 'wait-for-start-byte',
  WAIT_FOR_HEADER = 'wait-for-header',
  WAIT_FOR_PAYLOAD = 'wait-for-payload',
  RECEIVED = 'received',
}

enum ACRCOM_PROTOCOL_VERSION {
  ACRCOM_PROTO_VER_LEGACY = 0,
  ACRCOM_PROTO_VER_NG = 1,
}

enum ACRCOM_FRAME_TYPE_NG {
  ACRCOM_FRAME_TYPE_NG_STATIC = 0,
  ACRCOM_FRAME_TYPE_NG_DYNAMIC = 1,
}

enum ACRCOM_COMMAND_ID {
  ACRCOM_CMD_LUA_EXECUTE = 1,
  ACRCOM_CMD_LUA_EXECUTE_ISOLATED = 2,
}

// Serial framing:
// 0x00 - start of frame
// uint16 - length of data
// uint16 - bit inverted length of data
// uint16 - crc16 of what follows
const acrComCtx = {
  receiverState: ACRCOM_RECEIVER_STATE.IDLE,
  frameReceiveTimestamp: 0,
  receiverExpectedLength: 0,
  receiverExpectedCrc16: 0,
  receivedFrame: [],
  rxBuffer: [],
}

let MAX_FRAME_TIMEOUT = 300
// const MIN_INTER_FRAME_DELAY = 10;

const crc16_ccitt_update = (b: number, crc: number): number => {
  //For each bit in the data byte, starting from the leftmost bit
  for (let i = 7; i >= 0; i -= 1) {
    // If leftmost bit of the CRC is 1, we will XOR with
    // the polynomial later
    const xor_flag = crc & 0x8000

    // Shift the CRC, and append the next bit of the
    // message to the rightmost side of the CRC
    crc <<= 1
    crc &= 0xffff

    if ((b & (1 << i)) !== 0) {
      crc |= 1
    }

    // Perform the XOR with the polynomial
    if (xor_flag !== 0) {
      crc ^= 0x1021
    }
  }

  crc &= 0xffff
  return crc
}

const crc16_calculate_algorithm = (data: Uint8Array): number => {
  let crc = 0xffff

  // Update the CRC using the data
  for (let i = 0; i < data.length; i++) {
    const b = data[i]
    crc = crc16_ccitt_update(b, crc)
  }

  // Augment 16 zero-bits
  for (let i = 0; i < 2; i++) {
    crc = crc16_ccitt_update(0, crc)
  }

  return crc
}

const byteOf = (inp: number, byteNumber: number): number => {
  return (inp >> (byteNumber * 8)) & 0xff
}

// Serial framing:
// 0x00 - start of frame
// uint16 - length of data
// uint16 - bit inverted length of data
// uint16 - crc16 of what follows
// -> higher layer frame
const acrComSendFramedData = async (port, data: Uint8Array) => {
  //Format frame
  const chunks = []
  let length = data.length & 0xffff
  let lengthInverted = ~length & 0xffff
  let crc16 = crc16_calculate_algorithm(data)
  chunks.push(
    Buffer.from(
      new Uint8Array([
        0x00,
        byteOf(length, 0),
        byteOf(length, 1),
        byteOf(lengthInverted, 0),
        byteOf(lengthInverted, 1),
        byteOf(crc16, 0),
        byteOf(crc16, 1),
      ])
    )
  )
  chunks.push(Buffer.from(data))

  const rawdata = Buffer.concat(chunks)

  const bufferToHex = (buffer: Uint8Array): string => {
    return buffer.reduce((acc, x) => {
      acc += `${x.toString(16)}:`
      return acc
    }, '')
  }

  console.log(`tx: ${bufferToHex(rawdata)}`)

  try {
    await port.current.write(rawdata)
    return true
  } catch (error) {
    console.error('[BOOTLOADER] Error writing serial port!', error)
    return false
  }
}

// const applicationSendResetDevice = async (port): Promise<boolean> => {
//   console.log(`[BOOTLOADER] Send application protocol reset`);
//   return await sendFrame(FrameTypes.RESET_BOARD, null, false, port.current);
// }

const acrComSendDynamicNGFrame = async (port, id, data: Uint8Array) => {
  const chunks = []
  chunks.push(
    Buffer.from(
      new Uint8Array([
        byteOf(ACRCOM_PROTOCOL_VERSION.ACRCOM_PROTO_VER_NG, 0),
        byteOf(ACRCOM_FRAME_TYPE_NG.ACRCOM_FRAME_TYPE_NG_DYNAMIC, 0),
        byteOf(id, 0),
      ])
    )
  )
  chunks.push(Buffer.from(data))
  const rawdata = Buffer.concat(chunks)
  return await acrComSendFramedData(port, rawdata)
}

const acrComReceiverStart = (maxTimeout = 300) => {
  MAX_FRAME_TIMEOUT = maxTimeout

  // reset state machine to default state
  acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_START_BYTE
  acrComCtx.frameReceiveTimestamp = 0
  acrComCtx.rxBuffer = []

  // externally: call FSM acrComReceiverFSM() function
  // in periodic tick (1 or 10 or 100 ms) tick is OK
  // in on receive event passing received bytes data as buffer in eventData
}

const acrComReceiverGetState = () => {
  return acrComCtx.receiverState
}

const acrComReceiverGetReceivedFrame = () => {
  if (acrComCtx.receiverState === ACRCOM_RECEIVER_STATE.RECEIVED) {
    return acrComCtx.receivedFrame
  } else {
    return []
  }
}

const acrComReceiverFSM = async (
  port: React.MutableRefObject<AcriosSerial>,
  event: EVENT_TYPE,
  eventData?
) => {
  const now = new Date().valueOf()
  // const previousReceiverState = acrComCtx.receiverState;

  if (acrComCtx.receiverState !== ACRCOM_RECEIVER_STATE.IDLE) {
    if (event === EVENT_TYPE.ON_RECEIVED) {
      acrComCtx.rxBuffer.push(...eventData)
    }
  }

  switch (acrComCtx.receiverState) {
    case ACRCOM_RECEIVER_STATE.IDLE:
      break
    case ACRCOM_RECEIVER_STATE.WAIT_FOR_START_BYTE:
      let startByte = acrComCtx.rxBuffer.shift()
      if (startByte === 0x00) {
        // 0x00 is the start byte of every serial command
        acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_HEADER
        acrComCtx.frameReceiveTimestamp = now
      }
      break
    case ACRCOM_RECEIVER_STATE.WAIT_FOR_HEADER:
      if (acrComCtx.rxBuffer.length >= 6) {
        // size of the serial header is 3xu16 -> 6 bytes
        let length = acrComCtx.rxBuffer[0] + acrComCtx.rxBuffer[1] * 256
        let lengthInverted = acrComCtx.rxBuffer[2] + acrComCtx.rxBuffer[3] * 256
        let crc16Payload = acrComCtx.rxBuffer[4] + acrComCtx.rxBuffer[5] * 256
        if (length === (~lengthInverted & 0xffff)) {
          acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_PAYLOAD
          acrComCtx.receiverExpectedLength = length
          acrComCtx.receiverExpectedCrc16 = crc16Payload
        } else {
          acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_START_BYTE
        }
      } else {
        if (now - acrComCtx.frameReceiveTimestamp >= MAX_FRAME_TIMEOUT) {
          acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_START_BYTE
        }
      }
      break

    case ACRCOM_RECEIVER_STATE.WAIT_FOR_PAYLOAD:
      if (acrComCtx.rxBuffer.length >= acrComCtx.receiverExpectedLength + 6) {
        // wait for specified amount of bytes + header size

        const payloadCandidate = acrComCtx.rxBuffer.slice(
          6,
          6 + acrComCtx.receiverExpectedLength
        )
        const crc16Calculated = crc16_calculate_algorithm(
          new Uint8Array(payloadCandidate)
        )

        if (crc16Calculated === acrComCtx.receiverExpectedCrc16) {
          // WOW, CRC16 matches
          // drop consumed data
          for (
            let tmp = 0;
            tmp < 6 + acrComCtx.receiverExpectedLength;
            tmp += 1
          ) {
            acrComCtx.rxBuffer.shift()
          }

          acrComCtx.receivedFrame = payloadCandidate
          acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.RECEIVED
        }
      } else {
        if (now - acrComCtx.frameReceiveTimestamp >= MAX_FRAME_TIMEOUT) {
          acrComCtx.receiverState = ACRCOM_RECEIVER_STATE.WAIT_FOR_START_BYTE
        }
      }
      break
  }

  //if (previousReceiverState !== acrComCtx.receiverState) {
  //  console.warn(`[ACRCOM] Receiver state changed ${previousReceiverState} => ${acrComCtx.receiverState}`);
  //}
}

export {
  acrComReceiverGetState,
  acrComReceiverGetReceivedFrame,
  acrComReceiverStart,
  acrComReceiverFSM,
  ACRCOM_RECEIVER_STATE,
  ACRCOM_COMMAND_ID,
  crc16_calculate_algorithm,
  acrComSendFramedData,
  acrComSendDynamicNGFrame,
}
