import 'reflect-metadata'
import { container } from 'tsyringe'
import { useState } from 'react'

import { Pane, SplitPane } from '@dtinsight/molecule/esm/components'
import { EditorView } from '@dtinsight/molecule/esm//workbench/editor'
import { SidebarView } from '@dtinsight/molecule/esm//workbench/sidebar'
import { ActivityBarView } from '@dtinsight/molecule/esm/workbench/activityBar'
import { StatusBarView } from '@dtinsight/molecule/esm/workbench/statusBar'
import { PanelView } from '@dtinsight/molecule/esm//workbench/panel'

import { ID_APP } from '@dtinsight/molecule/esm/common/id'
import { APP_PREFIX } from '@dtinsight/molecule/esm/common/const'
import {
  classNames,
  getFontInMac,
  prefixClaName,
} from '@dtinsight/molecule/esm/common/className'

import { connect } from '@dtinsight/molecule/esm/react'

import {
  ILayoutController,
  LayoutController,
} from '@dtinsight/molecule/esm/controller/layout'
import { LayoutService } from '@dtinsight/molecule/esm/services'
import { ILayout } from '@dtinsight/molecule/esm/model/workbench/layout'
import { IWorkbench } from '@dtinsight/molecule/esm/model/workbench'

import MyMenuBarView from './menuBar'
import Welcome from './Welcome'
import { useEffect } from 'react'
import molecule from '@dtinsight/molecule'
import { useStatusBar } from '../hooks/useStatusBar'
import { useGlobalState } from '../state/state'

const mainBenchClassName = prefixClaName('mainBench')
const workbenchClassName = prefixClaName('workbench')
const compositeBarClassName = prefixClaName('compositeBar')
const appClassName = classNames(APP_PREFIX, getFontInMac())

const layoutController = container.resolve(LayoutController)
const layoutService = container.resolve(LayoutService)

/**
 * WorkbenchView component
 */
const WorkbenchView = (props: IWorkbench & ILayout & ILayoutController) => {
  const { activityBar, menuBar, sidebar } = props
  const [_outputPanelHeight, setOutputPanelHeight] =
    useGlobalState('outputPanelHeight')

  const statusBar = useStatusBar()

  const [panelEditorRatio, setPanelEditorRatio] = useState(['70%', '30%'])
  const [sidebarRatio, setSidebarRatio] = useState(['20%', '80%'])

  /**
   * Handle resize OutputPanel
   */
  useEffect(() => {
    if (typeof panelEditorRatio[1] === 'number') {
      const outputHeight = panelEditorRatio[1]
      setOutputPanelHeight(outputHeight)
    }
  }, [panelEditorRatio])

  /**
   * Initialization
   */
  useEffect(() => {
    molecule.editor.setEntry(<Welcome />)
    statusBar.init()
  }, [])

  return (
    <div
      id={ID_APP}
      className={classNames(appClassName, 'myMolecule')}
      tabIndex={0}
    >
      <div className={workbenchClassName}>
        {!menuBar.hidden && <MyMenuBarView />}
        <div className={mainBenchClassName}>
          <div className={compositeBarClassName}>
            {!activityBar.hidden && <ActivityBarView />}
          </div>
          <SplitPane
            sizes={sidebarRatio}
            allowResize={[true, true]}
            split="vertical"
            onChange={(e) => setSidebarRatio(e as any)}
          >
            {/* folder tree  */}
            <Pane className={sidebar.hidden ? 'hidden' : ''}>
              <SidebarView />
            </Pane>

            {/* editor + panel  */}
            <SplitPane
              sizes={panelEditorRatio}
              allowResize={[false, false]} // Don't auto-resize with Window
              split="horizontal"
              onChange={(e) => setPanelEditorRatio(e as any)}
            >
              {/* editor */}
              <Pane key="editorView">
                <EditorView entry={<Welcome />} />
              </Pane>

              {/* output panel  */}
              <Pane key="panelView">
                <PanelView />
              </Pane>
            </SplitPane>
          </SplitPane>
        </div>
      </div>

      <StatusBarView />
    </div>
  )
}

export default connect(layoutService, WorkbenchView, layoutController)
